var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var useInput = function (props) {
    var setState = props.setState;
    var setValue = function (name, value) {
        var names = name.split('.');
        if (!names.length)
            return;
        /**
         * @example
         * 引数の例
         * const name = 'user.address.prefecture'
         * const names = ['user', 'address', 'prefecture']
         * const value = '東京都'
         *
         * 更新対象のオブジェクトにアクセスするために、再起処理を行う
         * ① updateValue(prevState, ['user'], "東京都")
         * ② updateValue(prevState['user'], ['address', "prefecture"], "東京都")
         * ③ updateValue(prevState['user']['address'], ['prefecture'], "東京都")
         */
        var updateValue = function (acc, names, value) {
            var _a, _b, _c;
            var firstName = names[0], restNames = names.slice(1);
            // 末端の要素でない場合は再起処理を行う
            if (restNames.length) {
                var currentValue = acc[firstName];
                if (Array.isArray(currentValue)) {
                    var index = parseInt(restNames[0]);
                    var updatedValue = updateValue(currentValue[index], restNames.slice(1), value);
                    var newArray = currentValue.splice(index, 1, updatedValue);
                    return __assign(__assign({}, acc), (_a = {}, _a[firstName] = newArray, _a));
                }
                else {
                    return __assign(__assign({}, acc), (_b = {}, _b[firstName] = updateValue(acc[firstName], restNames, value), _b));
                }
            }
            else {
                return __assign(__assign({}, acc), (_c = {}, _c[firstName] = value, _c));
            }
        };
        return setState(function (prev) {
            return updateValue(prev, names, value);
        });
    };
    /**
     *
     * @summary
     * DOM 要素の `name` 属性に基づいた状態を更新します。
     * @param e - 入力要素の変更イベント．
     * @param options - オプションオブジェクト．
     *  - `type`: 入力要素の種類を示す文字列。`checkbox`の場合は`checked`プロパティが状態値として設定され、
     *            `array`の場合は`value`プロパティが文字列として取得され、コンマ区切りで配列化されます。
     *
     * @description
     * `options.type` が `checkbox` の場合，入力要素(e.target)の `checked` プロパティが状態値として設定されます．
     * `array` の場合は、入力要素の `value` プロパティが文字列として取得され、コンマ区切りで配列化されます。
     * それ以外の場合は、入力要素の `value` プロパティが設定されます。
     *
     * @example
     * const [state, setState] = useState({ user: { name: 'Mike', isAgreed: false } })
     *
     * <input type="text" name="user.name" onChange={handleInputChange} />
     * 'John'を入力 // { user: { name: 'John', isAgreed: false } }
     *
     * <input type="checkbox" name="user.isAgreed" onChange={(e) => handleInputChange(e, { type: 'checkbox' })} />
     * チェックボックスをオン // { user: { name: 'Mike', isAgreed: true } }
     */
    var handleInputChange = function (e, options) {
        var _a = e.target, checked = _a.checked, name = _a.name, type = _a.type, value = _a.value;
        if ((options === null || options === void 0 ? void 0 : options.type) === 'checkbox') {
            setValue(name, checked);
        }
        else if ((options === null || options === void 0 ? void 0 : options.type) === 'array') {
            var arrayValue = value
                ? value
                    .trim()
                    .split(',')
                    .map(function (item) {
                    var numValue = Number(item);
                    return isNaN(numValue) ? item : numValue;
                })
                : [];
            setValue(name, arrayValue);
        }
        else if ((options === null || options === void 0 ? void 0 : options.type) === 'radio') {
            handleRadioChange(e, value);
        }
        else if (type === 'number') {
            if (value === '')
                return setValue(name, null);
            setValue(name, Number(value));
        }
        else {
            setValue(name, value);
        }
    };
    var handleRadioChange = function (e, value) {
        var name = e.target.name;
        var numValue = Number(value);
        var literalOrBool = value === 'true'
            ? true
            : value === 'false'
                ? false
                : isNaN(numValue)
                    ? value
                    : numValue;
        setValue(name, literalOrBool);
    };
    var handleDateChange = function (date, name) {
        setValue(name, date);
    };
    return { handleInputChange: handleInputChange, handleDateChange: handleDateChange };
};

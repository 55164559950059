import { useEffect, useRef } from 'react';
import { useState } from 'react';
export var usePopper = function (_a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.disableHandleClickAway, disableHandleClickAway = _c === void 0 ? false : _c;
    var _d = useState(undefined), anchorEl = _d[0], setAnchorEl = _d[1];
    var popperRef = useRef(null);
    var onOpen = function (event) {
        setAnchorEl(anchorEl ? undefined : event.currentTarget);
    };
    var onClose = function (_) {
        setAnchorEl(undefined);
    };
    var handleClickAway = function (target, _) {
        if (popperRef.current && popperRef.current.contains(target)) {
            return;
        }
        setAnchorEl(undefined);
    };
    useEffect(function () {
        if (disableHandleClickAway)
            return;
        document.addEventListener('click', function (e) { return handleClickAway(e.target, e); });
        return function () {
            document.removeEventListener('click', function (e) { return handleClickAway(e.target, e); });
        };
    }, [disableHandleClickAway]);
    var open = Boolean(anchorEl);
    var popperId = open ? 'simple-popper' : undefined;
    return {
        popperRef: popperRef,
        anchorEl: anchorEl,
        onOpen: onOpen,
        onClose: onClose,
        open: open,
        popperId: popperId,
        handleClickAway: handleClickAway,
    };
};

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import { useState } from 'react';
export const PopoverMenu = ({ anchor, children, id }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (_jsxs(Box, { children: [_jsx(Box, { "aria-controls": open ? id : undefined, "aria-haspopup": "true", "aria-expanded": open ? 'true' : undefined, onClick: handleClick, children: anchor }), _jsx(Menu, { id: id, "aria-labelledby": `${id}-button`, anchorEl: anchorEl, open: open, onClose: handleClose, anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                }, transformOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                }, children: children(handleClose) })] }));
};

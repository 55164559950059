import { R } from '../utils/remeda';
/**
 * オブジェクトのフィールドからエラーメッセージを取得する
 * @param values オブジェクト
 * @param fieldName フィールド名（"."で区切った場合、ネストしたフィールドを参照できます）
 * @returns エラーメッセージ、エラーオブジェクトの配列、またはundefined
 */
var getFieldError = function (values, fieldName) {
    var fieldNames = fieldName.split('.');
    var getErrorTarget = function () {
        var _a, _b, _c;
        switch (fieldNames.length) {
            case 1:
                return values[fieldNames[0]];
            case 2:
                //@ts-ignore
                return (_a = values[fieldNames[0]]) === null || _a === void 0 ? void 0 : _a[fieldNames[1]];
            case 3:
                //@ts-ignore
                return (_c = (_b = values[fieldNames[0]]) === null || _b === void 0 ? void 0 : _b[fieldNames[1]]) === null || _c === void 0 ? void 0 : _c[fieldNames[2]];
            default:
                return undefined;
        }
    };
    return getErrorTarget();
};
export var isFieldError = function (fieldError) {
    if (fieldError === undefined) {
        return false;
    }
    else if (R.isArray(fieldError)) {
        return false;
    }
    else {
        return true;
    }
};
export var isFieldErrors = function (fieldError) {
    if (fieldError === undefined) {
        return false;
    }
    else if (R.isArray(fieldError)) {
        return true;
    }
    else {
        return false;
    }
};
export var getErrorMessage = function (name, value) {
    if (!value)
        return;
    var fieldError = getFieldError(value, name);
    if (isFieldError(fieldError)) {
        return fieldError.message;
    }
    else {
        return;
    }
};

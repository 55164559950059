import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from '@mui/material';
export const Terms = ({ LinkComponent, lang }) => {
    const jp = {
        text1: 'アカウントを作成すると、弊社',
        text2: 'サービス利用規約',
        text3: 'に同意したものとみなされます。',
    };
    const en = {
        text1: 'By creating an account, you agree to our ',
        text2: 'Terms of Service',
        text3: '.',
    };
    const textCollection = lang === 'ja' ? jp : en;
    return (_jsxs(Typography, { color: "GrayText", children: [textCollection.text1, _jsx(LinkComponent, { text: textCollection.text2 }), textCollection.text3] }));
};

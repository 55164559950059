import aspida from '@aspida/axios';
import api from '../api/$api';
import { atndAxios } from '../axios/atndAxios';
import { kotobukAxios } from '../axios/kotobukAxios';
import { kotobukApi } from '../kotobuk';
// atnd
var axiosOptions = {
    baseURL: "".concat(process.env.API_BASE_URL),
};
export var createApiClient = function () {
    return api(aspida(atndAxios, axiosOptions));
};
// kotobuk
var kotobukAxiosOptions = {
    baseURL: "".concat(process.env.KOTOBUK_API_BASE_URL),
    headers: process.env.KOTOBUK_API_KEY
        ? {
            'api-key': process.env.KOTOBUK_API_KEY,
        }
        : undefined,
};
export var createKotobukApiClient = function () {
    return kotobukApi(aspida(kotobukAxios, kotobukAxiosOptions));
};

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert, Snackbar, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { SnackbarContext, isAxiosError } from 'shared';
export const SnackbarProvider = ({ children }) => {
    const matches = useMediaQuery('(min-width:600px)');
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');
    const openSnackbar = (message, severity) => {
        setIsOpen(true);
        setMessage(message);
        setSeverity(severity);
    };
    const invalidNotification = () => {
        openSnackbar('入力内容に誤りがあります', 'error');
    };
    const notifyError = (error) => {
        if (isAxiosError(error)) {
            if (error.response) {
                const message = getErrorMessageFromStatus(error.response.status);
                openSnackbar(message, 'error');
            }
            else {
                // ネットワークエラーなど、レスポンスがない場合
                openSnackbar('ネットワークエラーが発生しました。', 'error');
            }
        }
        else if (error instanceof Error) {
            // const message = getErrorMessageFromStatus(error.stack)
            console.error('error.stack', error.stack);
        }
        else {
            openSnackbar('エラーが発生しました。', 'error');
        }
    };
    const closeSnackbar = (_, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsOpen(false);
    };
    return (_jsxs(SnackbarContext.Provider, { value: { openSnackbar, invalidNotification, notifyError }, children: [_jsx(Snackbar, { anchorOrigin: {
                    vertical: matches ? 'top' : 'bottom',
                    horizontal: 'center',
                }, open: isOpen, autoHideDuration: 7000, onClose: closeSnackbar, children: _jsx(Alert, { severity: severity, children: message }) }), children] }));
};
const getErrorMessageFromStatus = (status) => {
    switch (status) {
        case 400:
            return '不正なリクエストです。';
        case 401:
            return '認証に失敗しました。再度ログインしてください。';
        case 403:
            return 'アクセス権限がありません。';
        case 404:
            return 'リソースが見つかりません。';
        case 500:
            return 'サーバーで問題が発生しました。';
        default:
            return '未知のエラーが発生しました。';
    }
};

import { R } from 'shared';
const getFieldError = (name, value) => {
    var _a, _b, _c;
    const names = name.split('.');
    if (!value)
        return;
    switch (names.length) {
        case 1:
            // @ts-ignore
            return value[name];
        case 2:
            // @ts-ignore
            return (_a = value[names[0]]) === null || _a === void 0 ? void 0 : _a[names[1]];
        case 3:
            // @ts-ignore
            return (_c = (_b = value[names[0]]) === null || _b === void 0 ? void 0 : _b[names[1]]) === null || _c === void 0 ? void 0 : _c[names[2]];
        default:
            return undefined;
    }
};
const isFieldError = (fieldError) => {
    if (fieldError === undefined) {
        return false;
    }
    else if (R.isArray(fieldError)) {
        return false;
    }
    else {
        return true;
    }
};
const isFieldErrors = (fieldError) => {
    if (fieldError === undefined) {
        return false;
    }
    else if (R.isArray(fieldError)) {
        return true;
    }
    else {
        return false;
    }
};
const getRhfErrorMessage = (name, value) => {
    if (!value)
        return;
    const fieldError = getFieldError(name, value);
    if (isFieldError(fieldError)) {
        return fieldError.message;
    }
    else {
        return;
    }
};
const getFieldErrors = (name, value) => {
    if (!value)
        return [];
    const fieldError = getFieldError(name, value);
    if (isFieldErrors(fieldError)) {
        return fieldError.map((error) => {
            return error;
        });
    }
    else {
        return [];
    }
};
export { getFieldError, getFieldErrors, getRhfErrorMessage };

import useAspidaSWR from '@aspida/swr';
import { useCalendar } from './useCalendar';
import { createApiClient } from '../../aspida/createApiClient';
export var useReservationListByWeek = function (initialDate, shallowPush) {
    var api = createApiClient();
    var _a = useCalendar({
        initialDate: initialDate,
        initialMode: 'week',
        shallowPush: shallowPush,
    }), date = _a.date, to = _a.to, toCurrent = _a.toCurrent, toNext = _a.toNext, toPrev = _a.toPrev;
    var _b = useAspidaSWR(api.list_reservations_by_week._date(date), {
        // 1分
        refreshInterval: 1000 * 60 * 1,
    }).data, reservationListByWeek = _b === void 0 ? [] : _b;
    return {
        date: date,
        to: to,
        toCurrentWeek: toCurrent,
        toNextWeek: toNext,
        toPrevWeek: toPrev,
        reservationListByWeek: reservationListByWeek,
    };
};

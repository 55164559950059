var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDisclosure } from 'shared';
import { AppLoadingButton, ContainedButton, Flex } from '../mui';
export const AlertDialogButton = ({ agreeLabel, buttonProps, children, confirmDataCy, content, description, disagreeLabel, onAgree, onDisagree, submitButtonColor = 'error', title, triggerDataCy, }) => {
    const { getButtonProps, getDisclosureProps, onClose } = useDisclosure();
    const handleAgree = () => __awaiter(void 0, void 0, void 0, function* () {
        return onAgree().then(() => {
            onClose();
        });
    });
    const handleDisagree = () => {
        onDisagree === null || onDisagree === void 0 ? void 0 : onDisagree();
        onClose();
    };
    return (_jsxs(_Fragment, { children: [_jsx(ContainedButton, Object.assign({}, getButtonProps(), buttonProps, { sx: Object.assign(Object.assign({}, buttonProps === null || buttonProps === void 0 ? void 0 : buttonProps.sx), { borderRadius: 2 }), "data-cy": triggerDataCy, children: children })), _jsxs(Dialog, Object.assign({}, getDisclosureProps(), { "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description", PaperProps: { sx: { borderRadius: 2 } }, maxWidth: "xs", fullWidth: true, children: [_jsx(DialogTitle, { id: "alert-dialog-title", whiteSpace: "pre-wrap", children: title }), _jsxs(DialogContent, { children: [_jsx(DialogContentText, { id: "alert-dialog-description", whiteSpace: "pre-wrap", color: "#333", children: description }), content] }), _jsx(DialogActions, { children: _jsxs(Flex, { gap: 2, px: 1, children: [_jsx(Button, { color: "secondary", onClick: handleDisagree, children: disagreeLabel }), _jsx(AppLoadingButton, { variant: "contained", onClick: handleAgree, autoFocus: true, color: submitButtonColor, sx: { px: 2 }, dataCy: confirmDataCy, children: agreeLabel })] }) })] }))] }));
};

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from '@mui/material';
import { FlexCol } from './Box';
const h2Props = {
    variant: 'h2',
    fontSize: '18px',
    py: 1,
    px: 2,
};
export const Headline = (_a) => {
    var { children } = _a, typographyProps = __rest(_a, ["children"]);
    return (_jsx(Typography, Object.assign({}, h2Props, typographyProps, { children: children })));
};
export const LabeledContent = ({ children, dataCy, label, }) => {
    return (_jsxs(FlexCol, { children: [_jsxs(Typography, { variant: "subtitle2", children: ["\u30FB", label] }), _jsx(Typography, { variant: "body1", fontSize: 14, pl: 3, "data-cy": dataCy, whiteSpace: "pre-wrap", children: children })] }));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Sentry } from '../../utils/sentry';
import { getVitalSchema } from '../../validation/vital';
import { useSnackbar } from '../mui/useSnackbar';
export var useVital = function (_a) {
    var reservation = _a.reservation, updateReservation = _a.updateReservation;
    var _b = useSnackbar(), invalidNotification = _b.invalidNotification, openSnackbar = _b.openSnackbar;
    var _c = useForm({
        mode: 'onSubmit',
        resolver: zodResolver(getVitalSchema()),
        defaultValues: __assign(__assign({}, defaultValue), (reservation.vital && reservation.vital)),
    }), control = _c.control, getValues = _c.getValues, handleSubmit = _c.handleSubmit, reset = _c.reset, setValue = _c.setValue, watch = _c.watch;
    var submit = function (onClose) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, handleSubmit(function (_a) { return __awaiter(void 0, void 0, void 0, function () {
                    var error_1;
                    var vitalData = __rest(_a, []);
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _b.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, updateReservation({
                                        id: reservation.id,
                                        vital: __assign(__assign({ id: '' }, (reservation.vital && reservation.vital)), vitalData),
                                    })];
                            case 1:
                                _b.sent();
                                openSnackbar('バイタルを保存しました', 'success');
                                onClose === null || onClose === void 0 ? void 0 : onClose();
                                return [3 /*break*/, 3];
                            case 2:
                                error_1 = _b.sent();
                                Sentry.addBreadcrumb({
                                    message: 'バイタルの更新',
                                    data: __assign({}, vitalData),
                                    category: 'vital',
                                    timestamp: Date.now(),
                                });
                                Sentry.captureException(error_1);
                                openSnackbar('バイタルの保存に失敗しました', 'error');
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                }); }, function (e) {
                    console.error(e);
                    invalidNotification();
                })()];
        });
    }); };
    return {
        control: control,
        getValues: getValues,
        submit: submit,
        reset: reset,
        setValue: setValue,
        watch: watch,
    };
};
var defaultValue = {
    id: '',
    ht: null,
    bw: null,
    bt: null,
    s_bp_home: null,
    d_bp_home: null,
    s_bp_clinic: null,
    d_bp_clinic: null,
    hr: null,
    rr: null,
    sp02: null,
};

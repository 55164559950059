var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { LoadingButton as MuiLoadingButton } from '@mui/lab';
import { Button, styled } from '@mui/material';
import { useState } from 'react';
const buttonStyle = {
    fontWeight: 'bold',
    borderRadius: 4,
};
export const AppLoadingButton = styled((_a) => {
    var { children, dataCy, onClick } = _a, buttonProps = __rest(_a, ["children", "dataCy", "onClick"]);
    const [loading, setLoading] = useState(false);
    const handleClick = (e) => {
        setLoading(true);
        onClick === null || onClick === void 0 ? void 0 : onClick(e).finally(() => {
            setLoading(false);
        });
    };
    return (_jsx(MuiLoadingButton, Object.assign({ variant: "contained", loading: loading, onClick: handleClick }, buttonProps, { "data-cy": dataCy, children: children })));
})(buttonStyle);
const OutlinedButtonBase = (props) => (_jsx(Button, Object.assign({ variant: "outlined" }, props)));
export const OutlinedButton = styled(OutlinedButtonBase)(buttonStyle);
const ContainedButtonBase = (props) => (_jsx(Button, Object.assign({ variant: "contained", disableElevation: true }, props)));
export const ContainedButton = styled(ContainedButtonBase)(buttonStyle);

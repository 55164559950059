var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { formatDate } from 'shared';
import { DatePicker } from '../DatePicker';
export const AtomDatePicker = (props) => {
    const { atom, displayTime } = props, rest = __rest(props, ["atom", "displayTime"]);
    const [value, setValue] = useAtom(atom);
    return (_jsx(DatePicker, Object.assign({}, rest, { displayTime: displayTime, value: value, onChange: (date) => {
            if (!date) {
                setValue(RESET);
                return;
            }
            else if (displayTime) {
                setValue(formatDate(date, 'YYYY-MM-DD HH:mm'));
            }
            else {
                setValue(formatDate(date));
            }
        } })));
};

/* eslint-disable @typescript-eslint/naming-convention */
// yubinbango-core2というライブラリのコードです。コピーファイルを作成することでコンパイルエラー(TS1203)を回避しています。
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/naming-convention
var CACHE = [];
var Core = /** @class */ (function () {
    // @ts-ignore
    function Core(inputVal, callback) {
        if (inputVal === void 0) { inputVal = ''; }
        var _this = this;
        this.URL = 'https://yubinbango.github.io/yubinbango-data/data';
        this.REGION = [
            null,
            '北海道',
            '青森県',
            '岩手県',
            '宮城県',
            '秋田県',
            '山形県',
            '福島県',
            '茨城県',
            '栃木県',
            '群馬県',
            '埼玉県',
            '千葉県',
            '東京都',
            '神奈川県',
            '新潟県',
            '富山県',
            '石川県',
            '福井県',
            '山梨県',
            '長野県',
            '岐阜県',
            '静岡県',
            '愛知県',
            '三重県',
            '滋賀県',
            '京都府',
            '大阪府',
            '兵庫県',
            '奈良県',
            '和歌山県',
            '鳥取県',
            '島根県',
            '岡山県',
            '広島県',
            '山口県',
            '徳島県',
            '香川県',
            '愛媛県',
            '高知県',
            '福岡県',
            '佐賀県',
            '長崎県',
            '熊本県',
            '大分県',
            '宮崎県',
            '鹿児島県',
            '沖縄県',
        ];
        this.fetchAddressByPostalCode = function (postalCode) {
            return new Promise(function (resolve) {
                _this.getAddr(postalCode, function (value) {
                    resolve(value);
                });
            });
        };
        if (inputVal) {
            // 全角の数字を半角に変換 ハイフンが入っていても数字のみの抽出
            var a = inputVal.replace(/[０-９]/g, function (s) {
                return String.fromCharCode(s.charCodeAt(0) - 65248);
            });
            var b = a.match(/\d/g);
            var c = b === null || b === void 0 ? void 0 : b.join('');
            var yubin7 = this.chk7(c || '');
            // 7桁の数字の時のみ作動
            if (yubin7) {
                this.getAddr(yubin7, callback);
            }
            else {
                callback(this.addrDic());
            }
        }
    }
    Core.prototype.chk7 = function (val) {
        if (val.length === 7) {
            return val;
        }
    };
    Core.prototype.addrDic = function (region_id, region, locality, street, extended) {
        if (region_id === void 0) { region_id = ''; }
        if (region === void 0) { region = ''; }
        if (locality === void 0) { locality = ''; }
        if (street === void 0) { street = ''; }
        if (extended === void 0) { extended = ''; }
        return {
            region_id: region_id,
            region: region,
            locality: locality,
            street: street,
            extended: extended,
        };
    };
    Core.prototype.selectAddr = function (addr) {
        if (addr && addr[0] && addr[1]) {
            return this.addrDic(addr[0], 
            // @ts-ignore
            this.REGION[addr[0]], addr[1], addr[2], addr[3]);
        }
        else {
            return this.addrDic();
        }
    };
    // @ts-ignore
    Core.prototype.jsonp = function (url, fn) {
        // @ts-ignore
        window['$yubin'] = function (data) { return fn(data); };
        var scriptTag = document.createElement('script');
        scriptTag.setAttribute('type', 'text/javascript');
        scriptTag.setAttribute('charset', 'UTF-8');
        scriptTag.setAttribute('src', url);
        document.head.appendChild(scriptTag);
    };
    // @ts-ignore
    Core.prototype.getAddr = function (yubin7, fn) {
        var _this = this;
        var yubin3 = yubin7.substr(0, 3);
        // @ts-ignore
        // 郵便番号上位3桁でキャッシュデータを確認
        if (yubin3 in CACHE && yubin7 in CACHE[yubin3]) {
            // @ts-ignore
            return fn(this.selectAddr(CACHE[yubin3][yubin7]));
        }
        else {
            // @ts-ignore
            this.jsonp("".concat(this.URL, "/").concat(yubin3, ".js"), function (data) {
                // @ts-ignore
                CACHE[yubin3] = data;
                return fn(_this.selectAddr(data[yubin7]));
            });
            return undefined;
        }
    };
    return Core;
}());
export { Core };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { useState } from 'react';
import { v4 } from 'uuid';
import { useSnackbar } from './mui/useSnackbar';
import { useInput } from './useInput';
export var useTemplates = function () {
    var openSnackbar = useSnackbar().openSnackbar;
    var _a = useAtom(templatesAtom), templates = _a[0], setTemplates = _a[1];
    var _b = useState(false), isEdit = _b[0], setIsEdit = _b[1];
    var _c = useState(undefined), currentTemplateId = _c[0], setCurrentTemplateId = _c[1];
    var currentTemplate = templates.find(function (template) { return template.id === currentTemplateId; });
    var _d = useState({
        name: (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.name) || '',
        text: (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.text) || '',
    }), newTemplate = _d[0], setNewTemplate = _d[1];
    var handleInputChange = useInput({ setState: setNewTemplate }).handleInputChange;
    var toggleEdit = function () {
        var _a, _b;
        setIsEdit(function (prev) { return !prev; });
        setNewTemplate({
            name: (_a = currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.name) !== null && _a !== void 0 ? _a : '',
            text: (_b = currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.text) !== null && _b !== void 0 ? _b : '',
        });
    };
    var copyToClipboard = function () {
        var _a;
        navigator.clipboard.writeText((_a = currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.text) !== null && _a !== void 0 ? _a : '');
        openSnackbar('クリップボードにコピーしました', 'info');
    };
    var selectTemplate = function (id) {
        var template = templates.find(function (template) { return template.id === id; });
        if (!template)
            return;
        setCurrentTemplateId(template.id);
        setNewTemplate(template);
        setTemplates(function (prev) {
            var newTemplates = prev.filter(function (template) { return template.id !== id; });
            return __spreadArray([template], newTemplates, true);
        });
    };
    var addTemplate = function () {
        setTemplates(function (prev) { return __spreadArray([
            __assign(__assign(__assign({}, (currentTemplate && currentTemplate)), newTemplate), { id: v4() })
        ], prev, true); });
        openSnackbar('テンプレートを作成しました', 'success');
        setIsEdit(false);
    };
    var updateTemplate = function () {
        setTemplates(function (prev) {
            return prev.map(function (prevTemplate) {
                var _a;
                if (!currentTemplateId)
                    return prevTemplate;
                if (currentTemplate && ((_a = prevTemplate.id === currentTemplateId) !== null && _a !== void 0 ? _a : '')) {
                    return __assign(__assign({}, currentTemplate), newTemplate);
                }
                return prevTemplate;
            });
        });
        openSnackbar('テンプレートを更新しました', 'success');
        setIsEdit(false);
    };
    var removeTemplate = function () {
        if (!currentTemplateId)
            return;
        setTemplates(function (prev) {
            var removed = prev.filter(function (template) { return template.id !== currentTemplateId; });
            return removed;
        });
        setCurrentTemplateId(undefined);
        openSnackbar('テンプレートを削除しました', 'success');
    };
    return {
        templates: templates,
        currentTemplate: currentTemplate,
        newTemplate: newTemplate,
        handleInputChange: handleInputChange,
        selectTemplate: selectTemplate,
        addTemplate: addTemplate,
        updateTemplate: updateTemplate,
        removeTemplate: removeTemplate,
        copyToClipboard: copyToClipboard,
        isEdit: isEdit,
        toggleEdit: toggleEdit,
    };
};
var initialTemplate = {
    id: v4(),
    name: '',
    text: '',
};
var templatesAtom = atomWithStorage('templates', [
    initialTemplate,
]);

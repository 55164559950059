import { useState } from 'react';
export var usePagination = function (_a) {
    var _b = _a.initialPage, initialPage = _b === void 0 ? 0 : _b, initialPerPage = _a.initialPerPage, total = _a.total;
    var _c = useState(initialPage), currentPage = _c[0], setCurrentPage = _c[1];
    var _d = useState(initialPerPage), perPage = _d[0], setPerPage = _d[1];
    var lastPage = Math.ceil(total / perPage);
    var isFirstPage = currentPage === 0;
    var isLastPage = currentPage === lastPage;
    var prev = function () {
        if (isFirstPage)
            return;
        setCurrentPage(currentPage - 1);
    };
    var next = function () {
        if (isLastPage)
            return;
        setCurrentPage(currentPage + 1);
    };
    var toFirst = function () {
        setCurrentPage(0);
    };
    var toLast = function () {
        setCurrentPage(lastPage);
    };
    var toPage = function (page) {
        if (page > 0 && page <= lastPage) {
            setCurrentPage(page);
        }
    };
    return {
        currentPage: currentPage,
        perPage: perPage,
        lastPage: lastPage,
        isFirstPage: isFirstPage,
        isLastPage: isLastPage,
        prev: prev,
        next: next,
        toFirst: toFirst,
        toLast: toLast,
        toPage: toPage,
        setPerPage: setPerPage,
    };
};

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { InputAdornment, OutlinedInput } from '@mui/material';
import { useState } from 'react';
export const OutlinedNumberInput = (_a) => {
    var { adornment, max, min, onBlur } = _a, props = __rest(_a, ["adornment", "max", "min", "onBlur"]);
    const [value, setValue] = useState(undefined);
    return (_jsx(OutlinedInput, Object.assign({}, props, { type: "number", size: "small", endAdornment: adornment && _jsx(InputAdornment, { position: "end", children: adornment }), onChange: ({ target }) => {
            if (!target.value)
                return setValue(undefined);
            const numValue = Number(target.value);
            if (min && numValue < min) {
                return setValue(min);
            }
            else if (max && numValue > max) {
                return setValue(max);
            }
            else {
                setValue(numValue);
            }
        }, onBlur: () => {
            if (value === undefined)
                return;
            onBlur === null || onBlur === void 0 ? void 0 : onBlur(value);
        }, sx: { width: 120, bgcolor: 'white' }, inputProps: { min: 0, max: 15 } })));
};

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Edit, Done } from '@mui/icons-material';
import { Typography, IconButton, TextField } from '@mui/material';
import { useState } from 'react';
import { Flex } from '../mui';
export const EditableText = ({ disabled, initialValue, onSubmit, placeholder, }) => {
    const [value, setValue] = useState(initialValue);
    const [isEditing, setIsEditing] = useState(false);
    const handleToggleEdit = () => {
        setIsEditing(!isEditing);
    };
    const handleInputChange = (event) => {
        setValue(event.target.value);
    };
    const handleInputBlur = () => {
        if (!value) {
            setIsEditing(false);
            setValue(initialValue !== null && initialValue !== void 0 ? initialValue : '');
            return;
        }
        setIsEditing(false);
        onSubmit(value);
    };
    return (_jsx(_Fragment, { children: isEditing ? (_jsx(TextField, { value: value, onChange: handleInputChange, onBlur: handleInputBlur, placeholder: placeholder, autoFocus: true, fullWidth: true })) : (_jsxs(Flex, { alignItems: "center", justifyContent: "space-between", children: [_jsx(Typography, { variant: "body1", color: value ? 'inherit' : 'GrayText', children: value !== null && value !== void 0 ? value : placeholder }), _jsx(IconButton, { onClick: handleToggleEdit, disabled: disabled, children: isEditing ? _jsx(Done, { fontSize: "small" }) : _jsx(Edit, { fontSize: "small" }) })] })) }));
};

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { InputAdornment } from '@mui/material';
import { MenuItem, TextField } from '@mui/material';
import { PasswordTextField } from './PasswordTextField';
import { GridLayout } from '../mui/layouts/GridLayout';
export const TextFieldGrid = (_a) => {
    var { adornment, annotation, dataCy, hideLabel, inputRef, label, name, options, required, type = 'text', validationError, value, xs } = _a, inputProps = __rest(_a, ["adornment", "annotation", "dataCy", "hideLabel", "inputRef", "label", "name", "options", "required", "type", "validationError", "value", "xs"]);
    const commonProps = Object.assign({ name,
        required,
        type, value: value !== null && value !== void 0 ? value : '', xs, size: 'small', variant: 'outlined', fullWidth: true, error: !!validationError, helperText: validationError, 'data-cy': dataCy }, inputProps);
    return (_jsx(GridLayout, { label, annotation, xs, hideLabel, required, children: type === 'password' ? (_jsx(PasswordTextField, Object.assign({ inputRef: inputRef }, commonProps))) : (_jsxs(TextField, Object.assign({ inputRef: inputRef, SelectProps: { style: { fontSize: '14px' } }, InputProps: {
                style: {
                    borderRadius: '6px',
                },
                endAdornment: adornment && (_jsx(InputAdornment, { position: "end", children: adornment })),
            } }, commonProps, inputProps, { children: [_jsx(MenuItem, { sx: { py: 2 }, children: '' }), options === null || options === void 0 ? void 0 : options.map((option, index) => {
                    return (_jsx(MenuItem, { value: option.value, disabled: option.disabled, children: option.label }, `${name}-${option.label}-${index}`));
                })] }))) }));
};

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { TextFieldGrid } from '../TextFieldGrid';
export const AtomTextField = (props) => {
    const { atom, type } = props, inputProps = __rest(props, ["atom", "type"]);
    const [value, setValue] = useAtom(atom);
    return (_jsx(TextFieldGrid, Object.assign({}, inputProps, { type: type, value: value, onChange: (e) => {
            const { type, value } = e.target;
            if (!value) {
                setValue(RESET);
                return;
            }
            if (type === 'number') {
                const numValue = Number(value);
                if (Number.isNaN(numValue))
                    return;
                setValue(numValue);
            }
            else {
                setValue(value);
            }
        } })));
};

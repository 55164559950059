var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { zodResolver } from '@hookform/resolvers/zod';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { getSignupErrorMessage } from '../../../firebase';
import { useApi } from '../../../hooks';
import { useSnackbar } from '../../../hooks/mui/useSnackbar';
import { formatDate, convertToHalfWidth } from '../../../utils';
import { getUserSchema } from '../../../validation';
import { Core } from '../../../yubinbango';
import { isCreatingUserAtom } from '../stores/authStatusAtom';
import { createUserAtom, updateUserAtom, userAtom } from '../stores/userAtom';
export var useUserForm = function (_a) {
    var isEdit = _a.isEdit, onSubmit = _a.onSubmit, shouldSendEmailVerification = _a.shouldSendEmailVerification;
    var user = useAtomValue(userAtom);
    var _b = useAtom(isCreatingUserAtom), isCreatingUser = _b[0], setIsCreatingUser = _b[1];
    var api = useApi().api;
    var createUser = useSetAtom(createUserAtom);
    var updateUser = useSetAtom(updateUserAtom);
    var openSnackbar = useSnackbar().openSnackbar;
    var userSchema = getUserSchema();
    var _c = useForm({
        mode: 'onSubmit',
        resolver: zodResolver(isEdit
            ? userSchema.omit({ password: true, passwordConfirmation: true })
            : userSchema.omit({ notification: true }).superRefine(function (data, ctx) {
                if (data.password !== data.passwordConfirmation) {
                    ctx.addIssue({
                        path: ['passwordConfirmation'],
                        code: z.ZodIssueCode.custom,
                        message: 'パスワードが一致しません',
                    });
                }
            })),
        defaultValues: getInitialValue(user),
    }), control = _c.control, getValues = _c.getValues, handleSubmit = _c.handleSubmit, reset = _c.reset, setValue = _c.setValue, watch = _c.watch;
    var handleCreateUser = function () {
        var birthday = getValues('birthday');
        birthday && setValue('birthday', formatDate(birthday));
        return handleSubmit(function (data) { return __awaiter(void 0, void 0, void 0, function () {
            var user_1, error_1, message;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setIsCreatingUser(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, createUser(data)];
                    case 2:
                        user_1 = _a.sent();
                        shouldSendEmailVerification &&
                            api.verify_email.$post({ body: { email: user_1.email } });
                        openSnackbar('登録が完了しました', 'success');
                        onSubmit === null || onSubmit === void 0 ? void 0 : onSubmit(user_1);
                        return [3 /*break*/, 5];
                    case 3:
                        error_1 = _a.sent();
                        message = getSignupErrorMessage(error_1);
                        openSnackbar(message, 'error');
                        return [3 /*break*/, 5];
                    case 4:
                        setIsCreatingUser(false);
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); }, function (errors) {
            setIsCreatingUser(false);
            console.error(errors);
            openSnackbar('入力内容に誤りがあります', 'error');
        })();
    };
    var handleUpdateUser = function () {
        if (!user)
            throw new Error('未ログインのユーザーは更新できません');
        var birthday = getValues('birthday');
        birthday && setValue('birthday', formatDate(birthday));
        return handleSubmit(function (data) { return __awaiter(void 0, void 0, void 0, function () {
            var error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setIsCreatingUser(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, updateUser(__assign(__assign({}, user), data))];
                    case 2:
                        _a.sent();
                        openSnackbar('ユーザー情報を更新しました', 'success');
                        onSubmit === null || onSubmit === void 0 ? void 0 : onSubmit();
                        return [3 /*break*/, 5];
                    case 3:
                        error_2 = _a.sent();
                        if (error_2 instanceof Error) {
                            openSnackbar(error_2.message, 'error');
                        }
                        return [3 /*break*/, 5];
                    case 4:
                        setIsCreatingUser(false);
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); }, function (errors) {
            setIsCreatingUser(false);
            console.error(errors);
            openSnackbar('入力内容に誤りがあります', 'error');
        })();
    };
    var onPostalCodeChange = function (postalCode) { return __awaiter(void 0, void 0, void 0, function () {
        var core, halfWidthPostalCode, hyphenRemoved, address;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    core = new Core();
                    halfWidthPostalCode = convertToHalfWidth(postalCode.replace('ー', '-'));
                    setValue('postal_code', halfWidthPostalCode);
                    hyphenRemoved = halfWidthPostalCode.replace('-', '');
                    if (hyphenRemoved.length !== 7)
                        return [2 /*return*/];
                    return [4 /*yield*/, core.fetchAddressByPostalCode(hyphenRemoved)];
                case 1:
                    address = _a.sent();
                    setValue('address_prefecture', address.region);
                    setValue('address_city', address.locality);
                    setValue('address_street', address.street);
                    return [2 /*return*/];
            }
        });
    }); };
    return {
        user: user,
        updateUser: updateUser,
        control: control,
        handleCreateUser: handleCreateUser,
        handleUpdateUser: handleUpdateUser,
        onPostalCodeChange: onPostalCodeChange,
        watch: watch,
        reset: reset,
        isLoading: isCreatingUser,
    };
};
var initialFormValues = {
    email: '',
    password: '',
    passwordConfirmation: '',
    last_name: '',
    first_name: '',
    last_name_kana: null,
    first_name_kana: null,
    birthday: null,
    sex: 'M',
    phone_number: null,
    postal_code: null,
    address_prefecture: null,
    address_city: null,
    address_street: null,
    has_allergy: false,
    allergy: null,
    notification: true,
};
var getInitialValue = function (user) {
    var _a;
    if (!user)
        return initialFormValues;
    return __assign(__assign({}, initialFormValues), { email: user.email, last_name: user.last_name, first_name: user.first_name, last_name_kana: user.last_name_kana, first_name_kana: user.first_name_kana, birthday: user.birthday, postal_code: user.postal_code, address_prefecture: user.address_prefecture, address_city: user.address_city, address_street: user.address_street, phone_number: user.phone_number, sex: user.sex, has_allergy: user.has_allergy, allergy: user.allergy, current_hospital_id: user.current_hospital_id, default_hospital_id: user.default_hospital_id, notification: (_a = user.notification) !== null && _a !== void 0 ? _a : true });
};

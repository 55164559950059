export const healthCheckThStyles = {
    fontWeight: 'bold',
    position: 'sticky',
    whiteSpace: 'nowrap',
    zIndex: 10,
};
export const healthCheckCellDisplayStyles = {
    display: {
        sm: 'table-cell',
        xs: 'none',
    },
};

import { z } from './initialize';
import { getRules } from '.';
export var getNextReservationSchema = function () {
    var rules = getRules();
    return z.object({
        chiefComplaint: rules.stringRequired,
        estimatedTime: rules.numberRequired,
        reservation_date: rules.stringRequired,
        clinicAlias: rules.stringRequired,
    });
};

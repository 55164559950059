var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createElement as _createElement } from "react";
import { jsx as _jsx } from "react/jsx-runtime";
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { GridLayout } from '../mui/layouts/GridLayout';
export const RadioGrid = (_a) => {
    var { annotation, hideLabel, inputRef, label, onChange, options, required, value, xs } = _a, props = __rest(_a, ["annotation", "hideLabel", "inputRef", "label", "onChange", "options", "required", "value", "xs"]);
    return (_jsx(GridLayout, { label, annotation, xs, hideLabel, required, noGutters: true, children: _jsx(RadioGroup, Object.assign({ ref: inputRef, sx: { flexDirection: 'row' }, value: value }, props, { children: options.map((option, index) => (_createElement(FormControlLabel, Object.assign({}, props, { control: _jsx(Radio, { color: "primary", sx: { ml: 1 }, size: "small" }), key: index, label: option.label, value: option.value, checked: value === option.value, onChange: (e) => {
                    onChange === null || onChange === void 0 ? void 0 : onChange(e, option.value);
                } })))) })) }));
};

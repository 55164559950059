var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Controller, useController } from 'react-hook-form';
import { getErrorMessage } from 'shared';
import { SelectGrid } from '../inputs';
/**
 * react-hook-formラッパー
 */
export const RhfSelectGrid = (props) => {
    const { 
    // unused
    control, defaultValue, error, name, 
    // used
    onChange, placeholder, rules, shouldUnregister } = props, restProps = __rest(props, ["control", "defaultValue", "error", "name", "onChange", "placeholder", "rules", "shouldUnregister"]);
    const _a = useController({ name, control }), _b = _a.field, { onChange: rhfChange, ref } = _b, rest = __rest(_b, ["onChange", "ref"]), { formState: { errors } } = _a;
    const handleChange = (event, child) => {
        onChange && onChange(event, child);
        rhfChange(event);
    };
    const errorMessage = getErrorMessage(name, errors);
    return (_jsx(Controller, { name: name, control: control, render: ({ field }) => {
            return (_jsx(SelectGrid, Object.assign({ inputRef: ref, placeholder: placeholder, onChange: handleChange }, rest, { field }, restProps, { errorMessage: errorMessage })));
        } }));
};

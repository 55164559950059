import { useCallback } from 'react';
import { en, ja } from '../locales';
export var useLocale = function (locale) {
    var t = locale === 'en' ? en : ja;
    var text = useCallback(function (key) {
        // キーをドットで分割
        // 例: 'ADDRESS.POSTAL_CODE' => ['ADDRESS', 'POSTAL_CODE']
        var keys = key.split('.');
        var lastKey = keys[keys.length - 1];
        var isRoot = keys.length === 1;
        if (isRoot) {
            return t[lastKey];
        }
        var value = keys.reduce(function (acc, key) {
            return acc[key];
        }, t);
        if (typeof value === 'string') {
            return value;
        }
        else {
            return '';
        }
    }, [t]);
    return { locale: locale, t: t, text: text };
};

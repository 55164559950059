import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography, TextField, } from '@mui/material';
import { useState, useEffect } from 'react';
import { formatDate, useInsertTemplate } from 'shared';
import { AppLoadingButton, ContainedButton, Flex, FlexCol, LabeledContent, } from '../mui';
export const ReservationConfirmDialog = ({ onClose, reserveData, submit, }) => {
    const [memo, setMemo] = useState('');
    const { insertToText, templates } = useInsertTemplate({
        text: memo,
        setText: setMemo,
    });
    useEffect(() => {
        var _a;
        setMemo((_a = reserveData === null || reserveData === void 0 ? void 0 : reserveData.memo) !== null && _a !== void 0 ? _a : '');
    }, [reserveData === null || reserveData === void 0 ? void 0 : reserveData.memo]);
    return (_jsx(Dialog, { open: !!reserveData, onClose: onClose, PaperProps: { sx: { borderRadius: 2 } }, maxWidth: "sm", fullWidth: true, disableAutoFocus: true, children: _jsxs(_Fragment, { children: [_jsx(DialogTitle, { fontSize: 18, children: "\u4E88\u7D04\u306E\u78BA\u8A8D" }), _jsx(Divider, {}), _jsx(DialogContent, { children: _jsxs(FlexCol, { gap: 2, px: 2, children: [_jsxs(FlexCol, { gap: 1, children: [_jsx(Typography, { variant: "body2", mb: 1, children: "\u4E0B\u8A18\u306E\u5185\u5BB9\u3067\u4E88\u7D04\u3092\u78BA\u5B9A\u3057\u3066\u3082\u3088\u308D\u3057\u3044\u3067\u3059\u304B\uFF1F" }), _jsx(LabeledContent, { label: "\u4E88\u7D04\u65E5\u6642", children: formatDate(reserveData === null || reserveData === void 0 ? void 0 : reserveData.reservation_time, 'MM/DD(ddd) HH:mm') }), _jsxs(LabeledContent, { label: "\u8A3A\u5BDF\u6642\u9593\u4E88\u6E2C", children: [reserveData === null || reserveData === void 0 ? void 0 : reserveData.reservation_estimation_time, "\u5206"] })] }), _jsx(Flex, {}), _jsxs(FlexCol, { gap: 1, flex: "1 1 auto", children: [_jsx(Flex, { flexWrap: "wrap", gap: 1, children: templates.map((template, index) => {
                                            return (_jsx(ContainedButton, { onClick: () => {
                                                    insertToText(index);
                                                }, children: template.label }, index));
                                        }) }), _jsx(TextField, { value: memo, name: "text", onChange: (e) => {
                                            setMemo(e.target.value);
                                        }, placeholder: "\u30E1\u30E2", minRows: 5, multiline: true, "data-cy": "reservation-memo" })] })] }) }), _jsx(DialogActions, { children: _jsxs(Flex, { gap: 2, px: 1, pb: 2, children: [_jsx(Button, { color: "secondary", onClick: onClose, children: "\u9589\u3058\u308B" }), _jsx(AppLoadingButton, { onClick: () => {
                                    return submit(memo);
                                }, autoFocus: true, color: "primary", sx: { px: 2 }, dataCy: "reservation-submit-button", children: "\u78BA\u5B9A" })] }) })] }) }));
};

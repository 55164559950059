import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, Typography, ToggleButtonGroup, ToggleButton, } from '@mui/material';
import { appDayjs } from 'shared';
import { AppDateCalendar, Flex } from '../mui';
export const ReservationCalendarHead = ({ actions, date, handleChangeMode, mode, to, toCurrentWeek, toNextWeek, toPrevWeek, }) => {
    return (_jsxs(_Fragment, { children: [_jsxs(Flex, { justifyContent: { xs: 'space-around', sm: 'space-between' }, alignItems: "center", gap: { xs: 1, sm: 0 }, px: 2, width: "100%", children: [_jsx(Box, { width: { xs: '100px', sm: '140px' }, children: _jsx(Typography, { fontSize: { xs: 18, sm: 24 }, children: !!to ? (_jsx(AppDateCalendar, { value: date, InputProps: {
                                    sx: { fontSize: '24px', width: '180px' },
                                }, onChange: (date) => {
                                    if (!date)
                                        return;
                                    const newDate = date.format('YYYY-MM-DD');
                                    if (appDayjs(newDate).isValid()) {
                                        to(newDate);
                                    }
                                } })) : (date) }) }), _jsxs(Flex, { ml: "-10%", gap: 10, children: [_jsxs(Flex, { children: [_jsx(Button, { sx: Object.assign(Object.assign({}, buttonStyle), { borderRadius: '10px 0 0 10px' }), onClick: toPrevWeek, children: _jsx(Typography, { fontSize: buttonFontSize, children: `< 前週` }) }), _jsx(Button, { sx: Object.assign(Object.assign({}, buttonStyle), { borderLeft: 'none', borderRight: 'none' }), onClick: toCurrentWeek, children: _jsx(Typography, { fontSize: buttonFontSize, children: "\u4ECA\u9031" }) }), _jsx(Button, { sx: Object.assign(Object.assign({}, buttonStyle), { borderRadius: '0 10px 10px 0' }), onClick: toNextWeek, children: _jsx(Typography, { fontSize: buttonFontSize, children: `次週 >` }) })] }), mode && (_jsx(Flex, { children: _jsxs(ToggleButtonGroup, { value: mode, size: "small", onChange: handleChangeMode, exclusive: true, "aria-label": "calendar display mode", children: [_jsx(ToggleButton, { value: "day", children: "\u9031\u9593\u8868\u793A" }), _jsx(ToggleButton, { value: "week", children: "\u66DC\u65E5\u56FA\u5B9A" })] }) }))] }), _jsx(Flex, { display: { xs: 'none', sm: 'flex' }, children: actions })] }), _jsxs(Flex, { gap: 1, children: [_jsx(Typography, { fontSize: 12, children: "\u25CE: \u7A7A\u304D\u3042\u308A" }), _jsx(Typography, { fontSize: 12, children: "\u25CB: \u3084\u3084\u6DF7\u96D1" }), _jsx(Typography, { fontSize: 12, children: "\u25B3: \u6DF7\u96D1" }), _jsx(Typography, { fontSize: 12, children: "\u00D7: \u7A7A\u304D\u306A\u3057" })] })] }));
};
const buttonFontSize = { xs: '11px', sm: '14px' };
const buttonStyle = {
    height: { xs: '24px', sm: '36px' },
    minWidth: { xs: '40px', sm: '64px' },
    border: '1px solid #333',
    variant: 'text',
    whiteSpace: 'nowrap',
    color: '#333',
};

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Divider } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { sleep, useDisclosure } from 'shared';
import { AppLoadingButton, ContainedButton, Flex } from '../mui';
export const AppDialog = ({ buttonProps = { sx: {} }, cancelLabel, children, dialogTitleProps, maxWidth = 'sm', onCancel, onSave, disabledSave = false, saveLabel, title, triggerElement, loadingUntilRedirect, saveButton, disabled, saveButtonProps, }) => {
    const { getButtonProps, getDisclosureProps, onClose } = useDisclosure();
    const handleSave = () => __awaiter(void 0, void 0, void 0, function* () {
        if (loadingUntilRedirect) {
            onSave === null || onSave === void 0 ? void 0 : onSave();
            return sleep(10000);
        }
        else {
            return onSave === null || onSave === void 0 ? void 0 : onSave(onClose);
        }
    });
    const handleCancel = () => {
        onCancel === null || onCancel === void 0 ? void 0 : onCancel();
        onClose();
    };
    const { sx: buttonSx } = buttonProps, restButtonProps = __rest(buttonProps, ["sx"]);
    return (_jsxs(_Fragment, { children: [_jsx(ContainedButton, Object.assign({}, getButtonProps(), restButtonProps, { sx: Object.assign(Object.assign({}, buttonSx), { borderRadius: 2 }), disabled: disabled, children: triggerElement })), _jsxs(Dialog, Object.assign({}, getDisclosureProps(), { "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description", PaperProps: { sx: { borderRadius: 2 } }, maxWidth: maxWidth, fullWidth: true, children: [_jsx(DialogTitle, Object.assign({ id: "dialog-title", fontSize: 18 }, dialogTitleProps, { children: title })), _jsx(Divider, {}), _jsx(DialogContent, { children: children }), _jsx(DialogActions, { children: _jsxs(Flex, { gap: 2, px: 1, pb: 2, children: [onCancel && (_jsx(Button, { color: "secondary", onClick: handleCancel, children: cancelLabel })), saveButton
                                    ? saveButton
                                    : onSave && (_jsx(AppLoadingButton, Object.assign({}, saveButtonProps, { onClick: handleSave, autoFocus: true, color: "primary", sx: { px: 2 }, disabled: disabledSave, children: saveLabel })))] }) })] }))] }));
};

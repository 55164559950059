var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import useAspidaSWR from '@aspida/swr';
import { useSetAtom } from 'jotai';
import { useCallback, useState } from 'react';
import { useReservationActions } from './useReservation';
import { createApiClient } from '../../aspida';
import { createDummyReservationAtom } from '../../store/atoms/reservationListAtom';
import { R } from '../../utils';
import { formatDate, isSameTime, truncateToQuarterHour, } from '../../utils/dayjs';
import { Sentry } from '../../utils/sentry';
import { sortReservationList } from '../../utils/sort';
import { useSnackbar } from '../mui';
export var useReservationList = function (_a) {
    var _b, _c;
    var currentHospital = _a.currentHospital, date = _a.date;
    var openSnackbar = useSnackbar().openSnackbar;
    var api = createApiClient();
    var clinic_alias = currentHospital === null || currentHospital === void 0 ? void 0 : currentHospital.url_alias;
    var hospitalId = currentHospital === null || currentHospital === void 0 ? void 0 : currentHospital.id;
    var _d = useAspidaSWR(api.list_reservations_by_date._date(date), '$get', {
        key: "list_reservations_by_date?date=".concat(date, "&hospitalId=").concat(hospitalId),
        // 1分
        refreshInterval: 1000 * 60 * 1,
    }), data = _d.data, mutate = _d.mutate;
    var _e = useReservationActions(), deleteReservation = _e.deleteReservation, revertStatus = _e.revertStatus, updateReservation = _e.updateReservation;
    var createDummy = useSetAtom(createDummyReservationAtom);
    var _f = useExcludeInvalidReservations(), displayInvalidReservation = _f.displayInvalidReservation, excludeInvalidReservations = _f.excludeInvalidReservations, excludeInvalidReservationsListByDate = _f.excludeInvalidReservationsListByDate, setDisplayInvalidReservation = _f.setDisplayInvalidReservation;
    var reservationListByDate = excludeInvalidReservationsListByDate(data);
    // 予約順で表示するため
    var inOrderReservationsOrigin = (_b = reservationListByDate === null || reservationListByDate === void 0 ? void 0 : reservationListByDate.slots.map(function (slot) {
        return slot.reservations;
    }).flat().sort(function (a, b) {
        return a.queue_number - b.queue_number;
    })) !== null && _b !== void 0 ? _b : [];
    var inOrderReservations = excludeInvalidReservations(inOrderReservationsOrigin);
    var createDummyReservation = useCallback(function (payload) { return __awaiter(void 0, void 0, void 0, function () {
        var createdReservation_1, isSameDateReservation, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!clinic_alias)
                        return [2 /*return*/];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, createDummy(__assign({ clinic_alias: clinic_alias }, payload))];
                case 2:
                    createdReservation_1 = _a.sent();
                    isSameDateReservation = date === formatDate(createdReservation_1.reservation_time);
                    if (isSameDateReservation) {
                        mutate(function () {
                            if (!data)
                                return;
                            return insertReservation(data, createdReservation_1);
                        });
                    }
                    return [2 /*return*/, createdReservation_1];
                case 3:
                    error_1 = _a.sent();
                    Sentry.addBreadcrumb({
                        message: '電話予約の作成',
                        data: payload,
                        category: 'hospital',
                        timestamp: Date.now(),
                    });
                    Sentry.captureException(error_1);
                    openSnackbar('電話予約の作成に失敗しました', 'error');
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [clinic_alias, createDummy, data, date, mutate, openSnackbar]);
    var updateListReservation = useCallback(function (payload) { return __awaiter(void 0, void 0, void 0, function () {
        var updatedReservation_1, reservationTime_1, error_2;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, updateReservation(payload)
                        // 15分区切りに丸める
                    ];
                case 1:
                    updatedReservation_1 = _b.sent();
                    reservationTime_1 = truncateToQuarterHour((_a = updatedReservation_1.reservation_time) !== null && _a !== void 0 ? _a : '');
                    mutate(function () {
                        var _a, _b, _c;
                        if (!data)
                            throw new Error('prev is undefined');
                        // 挿入先スロットのインデックスを取得
                        var newSlotIndex = (_b = (_a = data.slots) === null || _a === void 0 ? void 0 : _a.findIndex(function (slot) {
                            return (formatDate("".concat(data.date, " ").concat(slot.start), 'YYYY-MM-DD HH:mm') ===
                                reservationTime_1);
                        })) !== null && _b !== void 0 ? _b : -1;
                        var updatedSlots = (_c = data.slots) === null || _c === void 0 ? void 0 : _c.map(function (slot, slotIndex) {
                            var _a;
                            // 更新前のデータを削除
                            var reservations = (_a = slot.reservations) === null || _a === void 0 ? void 0 : _a.map(function (reservation) {
                                return reservation.id === payload.id ? undefined : reservation;
                            }).filter(R.isTruthy);
                            // 更新後のデータを追加
                            if (slotIndex === newSlotIndex) {
                                reservations === null || reservations === void 0 ? void 0 : reservations.push(updatedReservation_1);
                            }
                            return __assign(__assign({}, slot), { reservations: reservations });
                        });
                        return __assign(__assign({}, data), { slots: updatedSlots });
                    });
                    return [3 /*break*/, 3];
                case 2:
                    error_2 = _b.sent();
                    Sentry.addBreadcrumb({
                        message: '予約の更新',
                        data: payload,
                        category: 'hospital',
                        timestamp: Date.now(),
                    });
                    Sentry.captureException(error_2);
                    openSnackbar('予約の更新に失敗しました', 'error');
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, [data, mutate, openSnackbar, updateReservation]);
    var deleteListReservation = useCallback(function (reservationId) { return __awaiter(void 0, void 0, void 0, function () {
        var updatedSlots_1, error_3;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, deleteReservation(reservationId)];
                case 1:
                    _b.sent();
                    updatedSlots_1 = (_a = reservationListByDate === null || reservationListByDate === void 0 ? void 0 : reservationListByDate.slots) === null || _a === void 0 ? void 0 : _a.map(function (slot) {
                        var _a;
                        return __assign(__assign({}, slot), { reservations: (_a = slot.reservations) === null || _a === void 0 ? void 0 : _a.filter(function (reservation) {
                                return reservation.id !== reservationId;
                            }) });
                    });
                    mutate(function () {
                        if (!data)
                            return;
                        return __assign(__assign({}, data), { slots: updatedSlots_1 !== null && updatedSlots_1 !== void 0 ? updatedSlots_1 : [] });
                    });
                    return [3 /*break*/, 3];
                case 2:
                    error_3 = _b.sent();
                    Sentry.addBreadcrumb({
                        message: '予約の削除',
                        data: { reservationId: reservationId },
                        category: 'hospital',
                        timestamp: Date.now(),
                    });
                    Sentry.captureException(error_3);
                    openSnackbar('予約の削除に失敗しました', 'error');
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, [
        data,
        deleteReservation,
        mutate,
        openSnackbar,
        reservationListByDate === null || reservationListByDate === void 0 ? void 0 : reservationListByDate.slots,
    ]);
    var revertReservationStatus = useCallback(function (reservationId) { return __awaiter(void 0, void 0, void 0, function () {
        var reverted, updatedSlots;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, revertStatus(reservationId)];
                case 1:
                    reverted = _b.sent();
                    updatedSlots = (_a = reservationListByDate === null || reservationListByDate === void 0 ? void 0 : reservationListByDate.slots) === null || _a === void 0 ? void 0 : _a.map(function (slot) {
                        var _a;
                        return __assign(__assign({}, slot), { reservations: (_a = slot.reservations) === null || _a === void 0 ? void 0 : _a.map(function (reservation) {
                                return reservation.id === reservationId ? reverted : reservation;
                            }) });
                    });
                    mutate(function () {
                        if (!data)
                            return;
                        return __assign(__assign({}, data), { slots: updatedSlots !== null && updatedSlots !== void 0 ? updatedSlots : [] });
                    });
                    return [2 /*return*/];
            }
        });
    }); }, [data, mutate, reservationListByDate === null || reservationListByDate === void 0 ? void 0 : reservationListByDate.slots, revertStatus]);
    var totalReservationCount = (_c = reservationListByDate === null || reservationListByDate === void 0 ? void 0 : reservationListByDate.slots) === null || _c === void 0 ? void 0 : _c.reduce(function (acc, slot) {
        var _a, _b;
        return acc + ((_b = (_a = slot.reservations) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0);
    }, 0);
    return {
        date: date,
        reservationListByDate: reservationListByDate
            ? sortReservationList(reservationListByDate)
            : undefined,
        inOrderReservations: inOrderReservations,
        totalReservationCount: totalReservationCount,
        createDummyReservation: createDummyReservation,
        updateListReservation: updateListReservation,
        deleteListReservation: deleteListReservation,
        revertReservationStatus: revertReservationStatus,
        displayInvalidReservation: displayInvalidReservation,
        setDisplayInvalidReservation: setDisplayInvalidReservation,
    };
};
export var useExcludeInvalidReservations = function () {
    var _a = useState(false), displayInvalidReservation = _a[0], setDisplayInvalidReservation = _a[1];
    var isValidReservation = function (reservation) {
        if (!reservation.status)
            return false;
        return !['cancelled', 'rescheduled'].includes(reservation.status);
    };
    var excludeInvalidReservations = useCallback(function (reservations) {
        if (displayInvalidReservation)
            return reservations;
        return reservations.filter(isValidReservation);
    }, [displayInvalidReservation]);
    var excludeInvalidReservationsListByDate = useCallback(function (reservationListByDate) {
        var _a;
        if (!reservationListByDate)
            return {
                date: '',
                slots: [],
                time_unit: 0,
            };
        if (displayInvalidReservation)
            return reservationListByDate;
        var updatedSlots = (_a = reservationListByDate.slots) === null || _a === void 0 ? void 0 : _a.map(function (slot) {
            var _a;
            return __assign(__assign({}, slot), { reservations: (_a = slot.reservations) === null || _a === void 0 ? void 0 : _a.filter(isValidReservation) });
        });
        return __assign(__assign({}, reservationListByDate), { slots: updatedSlots });
    }, [displayInvalidReservation]);
    return {
        displayInvalidReservation: displayInvalidReservation,
        setDisplayInvalidReservation: setDisplayInvalidReservation,
        excludeInvalidReservations: excludeInvalidReservations,
        excludeInvalidReservationsListByDate: excludeInvalidReservationsListByDate,
    };
};
// 開始時間が同じスロットに予約を追加する
var insertReservation = function (reservationList, reservation) {
    var _a;
    var updatedSlots = (_a = reservationList.slots) === null || _a === void 0 ? void 0 : _a.map(function (slot) {
        var _a;
        if (!reservation.reservation_time)
            return slot;
        var isSameSlot = isSameTime(reservation.reservation_time, timeToDateTime(slot.start).toISOString());
        if (isSameSlot) {
            return __assign(__assign({}, slot), { reservations: __spreadArray(__spreadArray([], ((_a = slot.reservations) !== null && _a !== void 0 ? _a : []), true), [reservation], false) });
        }
        else {
            return slot;
        }
    });
    return __assign(__assign({}, reservationList), { slots: updatedSlots });
};
var timeToDateTime = function (time) {
    var today = new Date();
    var dateString = today.toISOString().split('T')[0];
    var dateTimeString = dateString + 'T' + time;
    var dateTime = new Date(dateTimeString);
    return dateTime;
};

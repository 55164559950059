var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Fade, Paper, Popper, TableCell, Typography } from '@mui/material';
export const DescriptionCell = ({ children, maxWidth, minWidth, }) => {
    return (_jsx(TableCell, { sx: {
            maxWidth,
            minWidth,
            cursor: 'default',
        }, children: _jsx(Typography, { sx: {
                wordBreak: 'break-all',
            }, children: children }) }));
};
export const PopperWithTransition = (_a) => {
    var { children, disablePortal = true } = _a, popperProps = __rest(_a, ["children", "disablePortal"]);
    return (_jsx(Popper, Object.assign({}, popperProps, { transition: true, style: { zIndex: 100 }, placement: "auto", disablePortal: disablePortal, children: ({ TransitionProps }) => (_jsx(Fade, Object.assign({}, TransitionProps, { timeout: 450, children: _jsx(Paper, { sx: {
                    p: 2,
                    maxWidth: 400,
                    backgroundColor: '#fff',
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-all',
                }, children: children }) }))) })));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { appDayjs, formatDate } from './dayjs';
export var sortReservationList = function (reservationList) {
    var _a;
    var sortedReservationSlots = (_a = reservationList.slots) === null || _a === void 0 ? void 0 : _a.map(function (slot) {
        var _a;
        var sortedByArrivalTime = (_a = slot.reservations) === null || _a === void 0 ? void 0 : _a.sort(sortByArrivalTime);
        var sortedReservations = sortedByArrivalTime.sort(sortByStatus);
        return __assign(__assign({}, slot), { reservations: sortedReservations !== null && sortedReservations !== void 0 ? sortedReservations : [] });
    });
    var sortedSlots = sortedReservationSlots.sort(sortSlotByStart);
    return __assign(__assign({}, reservationList), { slots: sortedSlots !== null && sortedSlots !== void 0 ? sortedSlots : [] });
};
var sortByArrivalTime = function (current, next) {
    var currentTime = appDayjs(current.arrival_time);
    var nextTime = appDayjs(next.arrival_time);
    if (currentTime.isBefore(nextTime)) {
        return 1;
    }
    else if (currentTime.isAfter(nextTime)) {
        return -1;
    }
    else {
        return 0;
    }
};
var sortByStatus = function (current, next) {
    // 未着の場合は末尾に、それ以外は先頭に
    var bothScheduled = current.status === 'scheduled' && next.status === 'scheduled';
    if (bothScheduled) {
        return 0;
    }
    else if (current.status === 'scheduled') {
        return 1;
    }
    else {
        return -1;
    }
};
var sortSlotByStart = function (current, next) {
    var aStartAt = formatDate(current.start, 'HH:mm');
    var bStartAt = formatDate(next.start, 'HH:mm');
    if (aStartAt < bStartAt) {
        return -1;
    }
    else {
        return 1;
    }
};

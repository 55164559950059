var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { R, appDayjs } from 'shared';
export const DatePicker = (_a) => {
    var { dataCy, disabled, displayTime, error, initialDate, inputRef, name, onChange, openTo = 'day', placeholder, readOnly = false, value } = _a, rest = __rest(_a, ["dataCy", "disabled", "displayTime", "error", "initialDate", "inputRef", "name", "onChange", "openTo", "placeholder", "readOnly", "value"]);
    return (_jsx(DesktopDatePicker, Object.assign({}, rest, { ref: inputRef, onChange: (date) => {
            const newValue = date !== null && date !== void 0 ? date : '';
            if (R.isString(newValue)) {
                onChange === null || onChange === void 0 ? void 0 : onChange(newValue);
            }
            else {
                const newValue = date;
                onChange === null || onChange === void 0 ? void 0 : onChange(newValue.format(displayTime ? 'YYYY/MM/DD HH:mm' : 'YYYY/MM/DD'));
            }
        }, inputFormat: displayTime ? 'YYYY/MM/DD HH:mm' : 'YYYY/MM/DD', mask: displayTime ? '____/__/__ __:__:__' : '____/__/__', value: value || null, readOnly: readOnly, openTo: openTo, onOpen: () => {
            if (value)
                return;
            initialDate && (onChange === null || onChange === void 0 ? void 0 : onChange(appDayjs(initialDate)));
        }, dayOfWeekFormatter: (dayOfWeek) => {
            if (dayOfWeek === 'Su') {
                return '日';
            }
            else if (dayOfWeek === 'Mo') {
                return '月';
            }
            else if (dayOfWeek === 'Tu') {
                return '火';
            }
            else if (dayOfWeek === 'We') {
                return '水';
            }
            else if (dayOfWeek === 'Th') {
                return '木';
            }
            else if (dayOfWeek === 'Fr') {
                return '金';
            }
            else if (dayOfWeek === 'Sa') {
                return '土';
            }
            else {
                return '';
            }
        }, disabled: disabled, renderInput: (params) => (_jsx(TextField, Object.assign({}, params, { name: name, variant: "outlined", size: "small", error: !!error, helperText: error, inputProps: Object.assign(Object.assign({}, params.inputProps), { placeholder }), disabled: disabled, "data-cy": dataCy }))) })));
};

import Zod from 'zod';
var errMap = function (issue, _ctx) {
    var handleError = function (issue) {
        switch (issue.code) {
            case Zod.ZodIssueCode.invalid_type:
                return getInvalidTypeMessage(issue);
            case Zod.ZodIssueCode.invalid_date:
                return "\u65E5\u4ED8\u3092\u6B63\u3057\u304F\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044";
            case Zod.ZodIssueCode.too_small:
                return getTooSmallMessage(issue);
            case Zod.ZodIssueCode.too_big:
                return getTooBigMessage(issue);
            case Zod.ZodIssueCode.invalid_string:
                return "\u6587\u5B57\u5217\u3092\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044";
            case Zod.ZodIssueCode.invalid_date:
                return "\u65E5\u4ED8\u306E\u5F62\u5F0F\u304C\u6B63\u3057\u304F\u3042\u308A\u307E\u305B\u3093";
            default:
                return _ctx.defaultError;
        }
    };
    var message = handleError(issue);
    return {
        message: message,
        path: issue.path,
    };
};
var getInvalidTypeMessage = function (issue) {
    switch (issue.received) {
        case 'undefined':
        case 'null':
            return '必須項目です';
        case 'string':
            return issue.expected === 'number'
                ? '必須項目です'
                : '文字を入力してください';
        default:
            return "".concat(issue.expected, "\u3092\u671F\u5F85\u3057\u307E\u3057\u305F\u304C, ").concat(issue.received, "\u304C\u5165\u529B\u3055\u308C\u307E\u3057\u305F");
    }
};
var getTooBigMessage = function (issue) {
    switch (issue.type) {
        case 'string':
            return "".concat(issue.maximum, "\u6587\u5B57\u4EE5\u4E0B\u3067\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044");
        case 'number':
            return "".concat(issue.maximum, "\u4EE5\u4E0B\u306E\u5024\u3092\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044");
        default:
            return "\u5165\u529B\u306B\u8AA4\u308A\u304C\u3042\u308A\u307E\u3059";
    }
};
var getTooSmallMessage = function (issue) {
    switch (issue.type) {
        case 'string':
            return "".concat(issue.minimum, "\u6587\u5B57\u4EE5\u4E0A\u3067\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044");
        case 'number':
            return "".concat(issue.minimum, "\u4EE5\u4E0A\u306E\u5024\u3092\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044");
        default:
            return "\u5165\u529B\u306B\u8AA4\u308A\u304C\u3042\u308A\u307E\u3059";
    }
};
Zod.setErrorMap(errMap);
export { Zod as z };

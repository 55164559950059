var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, FormHelperText } from '@mui/material';
import { useController } from 'react-hook-form';
import { parseBoolean, R } from 'shared';
import { getRhfErrorMessage } from './utils';
import { RadioGrid } from '../inputs';
/**
 * react-hook-formラッパー
 */
export const RhfRadioGrid = (_a) => {
    var { control, disabledToBoolean, name, rules: _, shouldUnregister: __ } = _a, restProps = __rest(_a, ["control", "disabledToBoolean", "name", "rules", "shouldUnregister"]);
    const _b = useController({ name, control }), _c = _b.field, { onChange, ref } = _c, rest = __rest(_c, ["onChange", "ref"]), { formState: { errors } } = _b;
    const rhfOnChange = (_, value) => {
        const numberValue = Number(value);
        if (R.isBoolean(value)) {
            onChange({
                target: {
                    value,
                    name,
                },
            });
        }
        else if (R.isNumber(numberValue)) {
            onChange({
                target: {
                    value: numberValue,
                    name,
                },
            });
        }
        else if (R.isString(value)) {
            const newValue = disabledToBoolean ? value : parseBoolean(value);
            onChange({
                target: {
                    value: newValue,
                    name,
                },
            });
        }
    };
    const errorMessage = getRhfErrorMessage(name, errors);
    return (_jsxs(Box, { children: [_jsx(RadioGrid, Object.assign({}, rest, restProps, { inputRef: ref, onChange: rhfOnChange })), errorMessage && _jsx(FormHelperText, { error: true, children: errorMessage })] }));
};

import * as Sentry from '@sentry/nextjs';
import { useCallback } from 'react';
export var useSentry = function () {
    var pushErrorToSentry = useCallback(function (_a) {
        var category = _a.category, error = _a.error, message = _a.message, payload = _a.payload;
        Sentry.addBreadcrumb({
            category: category,
            data: {
                payload: payload,
            },
            message: message,
        });
        Sentry.captureException(error);
    }, []);
    return { pushErrorToSentry: pushErrorToSentry };
};

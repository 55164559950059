var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/naming-convention */
import { useController } from 'react-hook-form';
import { TextFieldGrid } from '../inputs';
import { getRhfErrorMessage } from '.';
/**
 * react-hook-formラッパー
 */
export const RhfTextFieldGrid = (props) => {
    const { control, defaultValue: __, error: ___, name, onBlur, placeholder, rules: _____, shouldUnregister: ______, type, value: _ } = props, restProps = __rest(props, ["control", "defaultValue", "error", "name", "onBlur", "placeholder", "rules", "shouldUnregister", "type", "value"]);
    const _a = useController({ name, control }), _b = _a.field, { onBlur: rhfOnBlur, onChange: rhfOnChange, ref } = _b, rest = __rest(_b, ["onBlur", "onChange", "ref"]), { formState: { errors } } = _a;
    const handleBluer = (e) => {
        onBlur === null || onBlur === void 0 ? void 0 : onBlur(e);
        rhfOnBlur();
    };
    const handleChange = (e) => {
        if (type === 'number') {
            const value = e.target.value;
            if (value === '') {
                rhfOnChange(Object.assign(Object.assign({}, e), { target: Object.assign(Object.assign({}, e.target), { value: null }) }));
            }
            else {
                const numValue = Number(value);
                rhfOnChange(Object.assign(Object.assign({}, e), { target: Object.assign(Object.assign({}, e.target), { value: numValue }) }));
            }
        }
        else {
            rhfOnChange(e);
        }
    };
    return (_jsx(TextFieldGrid, Object.assign({ inputRef: ref, type: type, placeholder: placeholder, validationError: getRhfErrorMessage(name, errors), onBlur: handleBluer, onChange: handleChange }, rest, restProps)));
};

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ListItemIcon, Typography, MenuItem, MenuList, Paper, } from '@mui/material';
import Link from 'next/link';
import { memo } from 'react';
import { useThemePallette } from 'shared';
import { Center, FlexCol } from '../mui';
export const MainNavigation = memo(({ navItems, onClose }) => {
    const palette = useThemePallette();
    return (_jsx(FlexCol, { gap: 2, children: _jsx(Paper, { children: _jsx(MenuList, { sx: { p: 0, borderBottom: '1px solid #DDD' }, children: navItems.map((navItem, index) => (_jsx(Link, { href: navItem.path, style: {
                        textDecoration: 'none',
                        color: palette.text.primary,
                    }, onClick: onClose, children: _jsx(MenuItem, { sx: {
                            borderBottom: navItems.length - 1 > index
                                ? '1px solid #DDD'
                                : undefined,
                            textAlign: 'center',
                        }, children: _jsxs(Center, { width: "100%", children: [navItem.icon && (_jsx(ListItemIcon, { children: navItem.icon })), _jsx(Typography, { ml: -1, fontSize: { base: 14, md: 16 }, minHeight: { base: '48px', md: '60px' }, lineHeight: { base: '48px', md: '60px' }, children: navItem.label })] }) }) }, navItem.label))) }) }) }));
});
MainNavigation.displayName = 'MainNavigation';

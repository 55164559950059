var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { parse } from 'querystring';
var flatValue = function (value) {
    var _a;
    return (_a = [value].flat()[0]) !== null && _a !== void 0 ? _a : '';
};
var toQueryString = function (query, pathname) {
    if (!query) {
        return '';
    }
    else if (typeof query === 'string') {
        return query;
    }
    else if (typeof query === 'object') {
        return Object.entries(query)
            .filter(function (_a) {
            var key = _a[0], value = _a[1];
            return value !== undefined && !pathname.includes("[".concat(key, "]"));
        })
            .map(function (_a) {
            var key = _a[0], value = _a[1];
            return "".concat(key, "=").concat(encodeURIComponent(flatValue(value)));
        })
            .join('&');
    }
};
export var toAsPath = function (urlObject, optionalQuery) {
    var pathname = urlObject.pathname, query = urlObject.query;
    if (!pathname) {
        throw new Error('pathname is required');
    }
    var parsed = typeof query === 'object' ? query : parse(query !== null && query !== void 0 ? query : '');
    var queryString = parsed
        ? toQueryString(__assign(__assign({}, parsed), (optionalQuery && optionalQuery)), pathname)
        : undefined;
    var path = pathname
        .replace(/\[(\w+?)\]/g, function (_, key) { var _a; return (parsed && ((_a = parsed[key]) === null || _a === void 0 ? void 0 : _a.toString())) || ":".concat(key); })
        .replace(/^\//, '');
    var url = "/".concat(path).concat(queryString ? "?".concat(queryString) : '');
    return url;
};

import { useState } from 'react';
export var useStep = function (initialStep, maxStep) {
    if (initialStep === void 0) { initialStep = 1; }
    if (maxStep === void 0) { maxStep = 2; }
    var _a = useState(initialStep), step = _a[0], setStep = _a[1];
    var next = function () { return setStep(function (prev) { return Math.min(prev + 1, maxStep); }); };
    var prev = function () { return setStep(function (prev) { return Math.max(prev - 1, 1); }); };
    var reset = function () { return setStep(initialStep); };
    var isLastStep = step === maxStep;
    var isFirstStep = step === 1;
    return { step: step, next: next, prev: prev, reset: reset, isLastStep: isLastStep, isFirstStep: isFirstStep };
};

import { Box, styled } from '@mui/material';
export const Flex = styled(Box)(() => ({
    display: 'flex',
}));
export const FlexCol = styled(Flex)(() => ({
    flexDirection: 'column',
}));
export const Center = styled(Flex)(() => ({
    justifyContent: 'center',
    alignItems: 'center',
}));

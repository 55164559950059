var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useCallback, useId, useState } from 'react';
/**
 * @description
 * `useDisclosure` は、一般的な`open`、`close`、`toggle`シナリオを処理するために使用されるカスタムフックです。
 * これは、 `Modal`, `Popover`, `Drawer` などのフィードバックコンポーネントを制御するために使用することができます。
 */
export var useDisclosure = function (props) {
    if (props === void 0) { props = {}; }
    var idProp = props.id, isOpenProp = props.isOpen, onCloseProp = props.onClose, onOpenProp = props.onOpen;
    var handleOpen = onOpenProp;
    var handleClose = onCloseProp;
    var _a = useState(props.defaultIsOpen || false), isOpenState = _a[0], setIsOpen = _a[1];
    var isOpen = isOpenProp !== undefined ? isOpenProp : isOpenState;
    var isControlled = isOpenProp !== undefined;
    var uid = useId();
    var id = idProp !== null && idProp !== void 0 ? idProp : "disclosure-".concat(uid);
    var onClose = useCallback(function () {
        if (!isControlled) {
            setIsOpen(false);
        }
        handleClose === null || handleClose === void 0 ? void 0 : handleClose();
    }, [isControlled, handleClose]);
    var onOpen = useCallback(function () {
        if (!isControlled) {
            setIsOpen(true);
        }
        handleOpen === null || handleOpen === void 0 ? void 0 : handleOpen();
    }, [isControlled, handleOpen]);
    var onToggle = useCallback(function () {
        if (isOpen) {
            onClose();
        }
        else {
            onOpen();
        }
    }, [isOpen, onOpen, onClose]);
    /**
     * @description
     * `getButtonProps` は、フィードバックコンポーネントのボタン(トリガー)に渡す必要があるすべてのプロパティを返します。
     * これは、`aria-expanded`、`aria-controls`、`onClick`などのプロパティを含みます。
     */
    var getButtonProps = function (props) {
        if (props === void 0) { props = {}; }
        return __assign(__assign({}, props), { 'aria-expanded': isOpen, 'aria-controls': id, onClick: function (event) {
                var _a;
                (_a = props.onClick) === null || _a === void 0 ? void 0 : _a.call(props, event);
                onToggle();
            } });
    };
    var getDisclosureProps = function (props) {
        if (props === void 0) { props = {}; }
        return __assign(__assign({}, props), { hidden: !isOpen, id: id, onClose: onClose, open: isOpen });
    };
    return {
        isOpen: isOpen,
        onOpen: onOpen,
        onClose: onClose,
        onToggle: onToggle,
        isControlled: isControlled,
        getButtonProps: getButtonProps,
        getDisclosureProps: getDisclosureProps,
    };
};

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
export const AppDateCalendar = (_a) => {
    var { InputProps } = _a, props = __rest(_a, ["InputProps"]);
    return (_jsx(DesktopDatePicker, Object.assign({}, props, { InputProps: Object.assign(Object.assign({}, InputProps), { disableUnderline: true }), inputFormat: 'YYYY-MM-DD', renderInput: (params) => (_jsx(TextField, Object.assign({}, params, { id: "app-date-calendar", variant: "standard", size: "small", focused: false }))) })));
};

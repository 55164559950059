import { useCallback } from 'react';
export var useInsertTemplate = function (_a) {
    var setText = _a.setText, text = _a.text;
    var insertToText = useCallback(function (templateIndex) {
        var template = templates[templateIndex];
        var newText = text + template.value;
        setText(newText);
    }, [text, setText]);
    return {
        insertToText: insertToText,
        templates: templates,
    };
};
var templates = [
    {
        label: 'Do処方',
        value: "Do\u51E6\u65B9 ",
    },
    {
        label: '処方調整',
        value: "\u51E6\u65B9\u8ABF\u6574 ",
    },
    {
        label: '採血予定',
        value: "\u63A1\u8840\u4E88\u5B9A ",
    },
    {
        label: '検査結果返却',
        value: "\u691C\u67FB\u7D50\u679C\u8FD4\u5374 ",
    },
    {
        label: '休職中',
        value: "\u4F11\u8077\u4E2D ",
    },
    {
        label: '診断書',
        value: "\u8A3A\u65AD\u66F8 ",
    },
    {
        label: '傷病手当意見書',
        value: "\u50B7\u75C5\u624B\u5F53\u610F\u898B\u66F8 ",
    },
    {
        label: '紹介状',
        value: "\u7D39\u4ECB\u72B6 ",
    },
];

import { useState } from 'react';
import { appDayjs, formatDate } from '../../utils/dayjs';
export var useCalendar = function (_a) {
    var _b = _a.dayValue, dayValue = _b === void 0 ? 7 : _b, initialDate = _a.initialDate, _c = _a.initialMode, initialMode = _c === void 0 ? 'day' : _c, shallowPush = _a.shallowPush;
    var _d = useState(initialDate !== null && initialDate !== void 0 ? initialDate : today), date = _d[0], setDate = _d[1];
    var _e = useState(initialMode), mode = _e[0], setMode = _e[1];
    var toPrev = function () {
        var prevDate = appDayjs(date)
            .subtract(dayValue, 'day')
            .format('YYYY-MM-DD');
        setDate(prevDate);
        shallowPush === null || shallowPush === void 0 ? void 0 : shallowPush(prevDate);
    };
    var toNext = function () {
        var nextDate = appDayjs(date).add(dayValue, 'day').format('YYYY-MM-DD');
        setDate(nextDate);
        shallowPush === null || shallowPush === void 0 ? void 0 : shallowPush(nextDate);
    };
    var toCurrent = function () {
        setDate(today);
        shallowPush === null || shallowPush === void 0 ? void 0 : shallowPush(today);
    };
    var to = function (date) {
        setDate(date);
        shallowPush === null || shallowPush === void 0 ? void 0 : shallowPush(date);
    };
    return {
        date: date,
        to: to,
        toPrev: toPrev,
        toNext: toNext,
        toCurrent: toCurrent,
        mode: mode,
        setMode: setMode,
    };
};
var today = formatDate(new Date());

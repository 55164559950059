var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { appDayjs, formatDate } from 'shared';
import { DatePicker } from './DatePicker';
import { GridLayout } from '../mui/layouts/GridLayout';
export const DatePickerGrid = (_a) => {
    var { annotation, hideLabel, label, onChange, required, xs = 6 } = _a, rest = __rest(_a, ["annotation", "hideLabel", "label", "onChange", "required", "xs"]);
    return (_jsx(GridLayout, { label, annotation, xs, hideLabel, required, children: _jsx(DatePicker, Object.assign({}, rest, { onChange: (e) => {
                if (e instanceof appDayjs) {
                    onChange === null || onChange === void 0 ? void 0 : onChange(formatDate(e));
                }
                else {
                    onChange === null || onChange === void 0 ? void 0 : onChange(e);
                }
            } })) }));
};

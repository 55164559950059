var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/naming-convention */
import { useController } from 'react-hook-form';
import { getRhfErrorMessage } from './utils';
import { DatePickerGrid } from '../inputs';
/**
 * react-hook-formラッパー
 */
export const RhfDatePickerGrid = (props) => {
    const { control, defaultValue: _, name, rules: __, shouldUnregister: ___ } = props, restProps = __rest(props, ["control", "defaultValue", "name", "rules", "shouldUnregister"]);
    const _a = useController({ name, control }), _b = _a.field, { ref } = _b, fieldProps = __rest(_b, ["ref"]), { formState: { errors } } = _a;
    return (_jsx(DatePickerGrid, Object.assign({ inputRef: ref, error: getRhfErrorMessage(name, errors) }, restProps, fieldProps)));
};

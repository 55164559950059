import { setCookie as nookiesSetCookie, parseCookies, destroyCookie, } from 'nookies';
var initialOptions = {
    maxAge: 1 * 24 * 60 * 60, // 1 day
    path: '/',
};
export var setCookie = function (key, value, options) {
    if (options === void 0) { options = initialOptions; }
    nookiesSetCookie(null, key, value, options);
};
export var getCookie = function (key) {
    return parseCookies()[key];
};
export var removeCookie = function (key) {
    destroyCookie(null, key, {
        path: '/',
    });
};
export var setHospitalIdToCookie = function (clinicAlias, hospital_id) {
    setCookie("".concat(clinicAlias, ".hospital_id"), hospital_id, {
        path: '/',
    });
};
export var getHospitalIdFromCookie = function (clinicAlias) {
    if (!clinicAlias)
        return;
    return getCookie("".concat(clinicAlias, ".hospital_id"));
};
export var setClinicAliasToCookie = function (clinic_alias) {
    setCookie('clinic_alias', clinic_alias, {
        path: '/',
    });
};
export var setAccessTokenToCookie = function (token) {
    setCookie('token', token, {
        maxAge: 15 * 60, // 15 min
        path: '/',
    });
};
export var setRefreshTokenToCookie = function (token) {
    setCookie('refresh_token', token, {
        maxAge: 1 * 24 * 60 * 60, // 1 day
        path: '/',
    });
};
export var setFirebaseIdToken = function (token) {
    setCookie('token', token, {
        maxAge: 60 * 60, // 1hour
        path: '/',
    });
};

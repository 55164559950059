import { createReservationAtom, deleteReservationAtom, revertStatusAtom, updateReservationAtom, useSetAtom, } from '../../store';
export var useReservationActions = function () {
    var createReservation = useSetAtom(createReservationAtom);
    var updateReservation = useSetAtom(updateReservationAtom);
    var deleteReservation = useSetAtom(deleteReservationAtom);
    var revertStatus = useSetAtom(revertStatusAtom);
    return {
        createReservation: createReservation,
        updateReservation: updateReservation,
        deleteReservation: deleteReservation,
        revertStatus: revertStatus,
    };
};

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Typography } from '@mui/material';
export const NewTabLink = (_a) => {
    var { children, href } = _a, other = __rest(_a, ["children", "href"]);
    return (_jsx("a", Object.assign({ target: "_blank", rel: "noreferrer", href: href, style: {
            color: '#1558d6',
            textDecoration: 'none',
            cursor: 'pointer',
        } }, other, { children: _jsx(Typography, { component: "p", variant: "body1", children: children }) })));
};

import { useAtomValue } from 'jotai';
import { useEffect, useMemo, useRef } from 'react';
import { punctuation } from '../../constants/punctuation';
import { slotListFamily } from '../../store';
export var useCalendarState = function (_a) {
    var ccid = _a.ccid, clinicAlias = _a.clinicAlias, date = _a.date, _b = _a.mode, mode = _b === void 0 ? 'day' : _b;
    var previousKey = useRef(undefined);
    var isSelectedCC = !!ccid && !!clinicAlias;
    var key = useMemo(function () {
        return "".concat(clinicAlias).concat(punctuation).concat(ccid).concat(punctuation).concat(date).concat(punctuation).concat(mode);
    }, [clinicAlias, ccid, date, mode]);
    var slotList = useAtomValue(slotListFamily(isSelectedCC ? key : undefined));
    useEffect(function () {
        var prevKey = previousKey.current;
        if (prevKey) {
            console.info('予約カレンダーを再取得', date);
            slotListFamily.remove(prevKey);
        }
        previousKey.current = key;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [key]);
    return { slotList: slotList };
};

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const AppLayout = ({ children, header, }) => {
    return (_jsxs("div", { style: {
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
            minHeight: '100dvh',
            backgroundColor: '#F7FAFC',
        }, children: [header, _jsx("div", { style: {
                    display: 'flex',
                    justifyContent: 'center',
                }, children: _jsx("div", { style: {
                        height: 'calc(100vh - 103px)',
                        flexGrow: 1,
                        padding: '8px 0',
                        maxWidth: 600,
                        overflowY: 'auto',
                    }, children: children }) })] }));
};

const findingOptions = [
    { label: '所見なし', value: false },
    { label: '所見あり', value: true },
];
const yesOrNoOptions = [
    { label: 'なし', value: false },
    { label: 'あり', value: true },
];
const severityOptions = [
    { label: '(-)', value: '(-)' },
    { label: '(±)', value: '(±)' },
    { label: '(+)', value: '(+)' },
    { label: '(++)', value: '(++)' },
    { label: '(+++)', value: '(+++)' },
    { label: '(++++)', value: '(++++)' },
];
export const healthCheckParameters = [
    {
        key: 'uploaded_at',
        label: 'アップロード日',
        unit: '',
    },
    {
        key: 'height',
        label: '身長',
        unit: 'cm',
    },
    {
        key: 'weight',
        label: '体重',
        unit: 'kg',
    },
    {
        key: 'bmi',
        label: 'BMI',
        unit: 'kg/m^2',
    },
    {
        key: 'abdominal_circumference',
        label: '腹囲',
        unit: 'cm',
    },
    {
        key: 'eyesight_right',
        label: '視力(右)',
        unit: '',
    },
    {
        key: 'eyesight_left',
        label: '視力(左)',
        unit: '',
    },
    {
        key: 'hearing_1000_right',
        label: '聴力(1000Hz 右)',
        options: findingOptions,
        unit: '',
    },
    {
        key: 'hearing_1000_left',
        label: '聴力(1000Hz 左)',
        options: findingOptions,
        unit: '',
    },
    {
        key: 'hearing_4000_right',
        label: '聴力(4000Hz 右)',
        options: findingOptions,
        unit: '',
    },
    {
        key: 'hearing_4000_left',
        label: '聴力(4000Hz 左)',
        options: findingOptions,
        unit: '',
    },
    {
        key: 'blood_pressure_systolic',
        label: '収縮期血圧',
        unit: 'mmHg',
    },
    {
        key: 'blood_pressure_diastolic',
        label: '拡張期血圧',
        unit: 'mmHg',
    },
    {
        key: 'total_cholesterol',
        label: 'TC',
        unit: 'mg/dL',
    },
    {
        key: 'ldl_cholesterol',
        label: 'LDL-C',
        unit: 'mg/dL',
    },
    {
        key: 'hdl_cholesterol',
        label: 'HDL-C',
        unit: 'mg/dL',
    },
    {
        key: 'triglycerides',
        label: '中性脂肪',
        unit: 'mg/dL',
    },
    {
        key: 'ast',
        label: 'AST(GOT)',
        unit: 'U/L',
    },
    {
        key: 'alt',
        label: 'ALT(GPT)',
        unit: 'U/L',
    },
    {
        key: 'g_gtp',
        label: 'γ-GTP(ガンマGTP)',
        unit: 'U/L',
    },
    {
        key: 'blood_sugar_level',
        label: '血糖値',
        unit: 'mg/dL',
    },
    {
        key: 'hba1c',
        label: 'HbA1c',
        unit: '%',
    },
    {
        key: 'rbc',
        label: '赤血球数',
        unit: '10^4/μL',
    },
    {
        key: 'hematocrit',
        label: 'ヘマトクリット値',
        unit: '%',
    },
    {
        key: 'mchc',
        label: 'MCHC',
        unit: '%',
    },
    {
        key: 'plt',
        label: '血小板数',
        unit: '10^5/μL',
    },
    {
        key: 'wbc',
        label: '白血球数',
        unit: '/μL',
    },
    {
        key: 'neutro',
        label: 'Neutro',
        unit: '%',
    },
    {
        key: 'lympho',
        label: 'Lympho',
        unit: '%',
    },
    {
        key: 'mono',
        label: 'Mono',
        unit: '%',
    },
    {
        key: 'eosino',
        label: 'Eosino',
        unit: '%',
    },
    {
        key: 'baso',
        label: 'Baso',
        unit: '%',
    },
    {
        key: 'urine_sugar',
        label: '尿糖定性',
        options: severityOptions,
        unit: '',
    },
    {
        key: 'urine_protein',
        label: '尿蛋白定性',
        options: severityOptions,
        unit: '',
    },
    {
        key: 'urine_occult_blood',
        label: '尿潜血',
        options: severityOptions,
        unit: '',
    },
    {
        key: 'urine_urobilinogen',
        label: '尿ウロビリノーゲン',
        options: severityOptions,
        unit: '',
    },
    {
        key: 'urine_bilirubin',
        label: '尿ビリルビン',
        options: severityOptions,
        unit: '',
    },
    {
        key: 'urine_ketone',
        label: '尿ケトン体',
        options: severityOptions,
        unit: '',
    },
    {
        key: 'urine_ph',
        label: '尿pH',
        unit: '',
    },
    {
        key: 'urine_specific_gravity',
        label: '尿比重',
        unit: '',
    },
    {
        key: 'bun',
        label: 'BUN',
        unit: 'mg/dL',
    },
    {
        key: 'e_gfr',
        label: 'eGFR',
        unit: 'mL/min/1.73m^2',
    },
    {
        key: 'has_dm',
        label: '糖尿病の既往',
        options: yesOrNoOptions,
        unit: '',
    },
    {
        key: 'is_smoking',
        label: '喫煙の有無',
        options: yesOrNoOptions,
        unit: '',
    },
    {
        key: 'uses_antihypertensive',
        label: '降圧薬内服',
        options: yesOrNoOptions,
        unit: '',
    },
    {
        key: 'ecg',
        label: '心電図',
        unit: '',
    },
    {
        key: 'history',
        label: '既往歴',
        unit: '',
    },
    {
        key: 'medication',
        label: '内服歴',
        unit: '',
    },
];

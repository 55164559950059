import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import { List, Typography, ListItemIcon, ListItem, ListItemText, } from '@mui/material';
import { AppButton } from '../Button';
import { FlexCol, Flex, AppLoadingButton } from '../mui';
export const AppDropzone = ({ acceptedFiles, getInputProps, getRootProps, isDragActive, upload, }) => {
    const inputProps = getInputProps();
    return (_jsxs(FlexCol, { width: "fullWidth", gap: 4, children: [_jsxs(FlexCol, Object.assign({ alignItems: "center", justifyContent: "center", p: 2, height: 180, border: "2px dashed #CCC", borderRadius: 4, color: "#454545", bgcolor: isDragActive ? '#F2F2F2' : '#F2F2F28C' }, getRootProps(), { children: [_jsx(CloudUploadIcon, { fontSize: "large", color: "inherit" }), _jsx(Typography, { my: 1.5, color: "GrayText", children: "\u753B\u50CF\u30D5\u30A1\u30A4\u30EB\u3092\u30C9\u30E9\u30C3\u30B0\uFF06\u30C9\u30ED\u30C3\u30D7\u3059\u308B\u304B\u3001\u4E0B\u306E\u30DC\u30BF\u30F3\u304B\u3089\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044" }), _jsxs(AppButton, { variant: "outlined", sx: { bgcolor: '#FFF', width: buttonWidth }, children: [_jsx("input", Object.assign({ type: "file", id: "file-upload", style: { display: 'none' }, multiple: true }, inputProps)), _jsx("label", { className: "cursor-pointer", htmlFor: "file-upload", children: "\u30D5\u30A1\u30A4\u30EB\u3092\u9078\u629E" })] })] })), upload && (_jsx(Flex, { justifyContent: "center", children: _jsx(AppLoadingButton, { variant: "contained", color: "primary", disabled: !acceptedFiles.length, onClick: upload, sx: { width: buttonWidth }, children: "\u30A2\u30C3\u30D7\u30ED\u30FC\u30C9" }) })), _jsxs(FlexCol, { alignItems: "start", children: [_jsx(Typography, { sx: { mb: 1 }, variant: "h6", children: "\u30A2\u30C3\u30D7\u30ED\u30FC\u30C9\u30D5\u30A1\u30A4\u30EB" }), _jsx(List, { dense: true, children: acceptedFiles.length ? (acceptedFiles.map((file, index) => {
                            return (_jsxs(ListItem, { disableGutters: true, children: [_jsx(ListItemIcon, { sx: { minWidth: 26 }, children: _jsx(FilePresentIcon, { fontSize: "small" }) }), _jsx(ListItemText, { primary: file.name })] }, index));
                        })) : (_jsx(Typography, { children: "\u30D5\u30A1\u30A4\u30EB\u304C\u9078\u629E\u3055\u308C\u3066\u3044\u307E\u305B\u3093" })) })] })] }));
};
const buttonWidth = 140;

import { z } from './initialize';
import { getRules } from '.';
export var getHospitalSchema = function () {
    var rules = getRules();
    return z.object({
        name: rules.string,
        name_kana: rules.string,
        email: rules.emailRequired,
        phone_number: rules.string,
        postal_code: rules.address.postalCode,
        address_prefecture: rules.address.prefecture,
        address_city: rules.address.city,
        address_street: rules.address.street,
    });
};

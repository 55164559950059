var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormHelperText, MenuItem, Select, InputLabel, FormControl, } from '@mui/material';
import { GridLayout } from '../mui';
export const SelectGrid = (_a) => {
    var { errorMessage, hideLabel, inputRef, label, onChange, options, placeholder, required, type = 'number', xs = 12, name, id = `select-${name}`, value } = _a, rest = __rest(_a, ["errorMessage", "hideLabel", "inputRef", "label", "onChange", "options", "placeholder", "required", "type", "xs", "name", "id", "value"]);
    const labelId = `${id}-label`;
    return (_jsx(GridLayout, { xs: xs, label: label, hideLabel: hideLabel, required: required, children: _jsxs(FormControl, { sx: { minWidth: 70 }, fullWidth: true, children: [placeholder && (_jsx(InputLabel, { id: labelId, variant: "outlined", size: "small", children: placeholder })), _jsx(Select, Object.assign({ type: type, labelId: labelId, id: id, name: name, label: placeholder, value: value !== null && value !== void 0 ? value : '', variant: "outlined", inputRef: inputRef, required: required, size: "small", onChange: onChange }, rest, { error: !!errorMessage, MenuProps: {
                        sx: {
                            maxHeight: '300px',
                        },
                    }, children: options === null || options === void 0 ? void 0 : options.map((option, index) => {
                        return (_jsx(MenuItem, { value: option.value, disabled: option.disabled, children: option.label }, index));
                    }) })), _jsx(FormHelperText, { error: !!errorMessage, children: errorMessage })] }) }));
};

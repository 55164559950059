import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from '@mui/material';
import { useMemo } from 'react';
import { appDayjs, formatDate, isNumberHealthcheckParam, useHealthCheck, useHealthCheckStandard, } from 'shared';
import { HCTd } from './HCTd';
import { HealthCheckRowHeaders } from './HealthCheckRowHeaders';
import { UploadedAtCell } from './UploadedAtCell';
import { healthCheckParameters } from './constants/parameters';
import { FlexCol } from '../mui/Box';
export const HealthCheckTable = ({ patient, userId, }) => {
    const { healthCheckStandardFlatten, isAbnormal, isKeyOfHealthcheckStandard } = useHealthCheckStandard({ sex: (patient === null || patient === void 0 ? void 0 : patient.sex) === 'F' ? 'woman' : 'man' });
    const { healthCheck } = useHealthCheck(userId);
    const headers = useMemo(() => [
        { label: '検査項目', key: 'checklist', width: '100px' },
        { label: '基準値', key: 'standard', width: '100px' },
        { label: '単位', key: 'unit', width: '100px' },
        ...healthCheck.records.map((record) => ({
            label: formatDate(record.consultation_date),
            key: record.id,
            width: undefined,
        })),
    ], [healthCheck.records]);
    return (_jsx(FlexCol, { maxWidth: "100%", children: _jsx(TableContainer, { sx: {
                maxHeight: '90vh',
                border: '1px solid #CCC',
            }, children: _jsxs(Table, { stickyHeader: true, sx: { width: 'auto' }, children: [_jsx(TableHead, { children: _jsx(TableRow, { children: headers.map((header) => (_jsx(TableCell, { component: "th", sx: Object.assign({ zIndex: 100, backgroundColor: '#ECECED', color: '#777', padding: '8px 12px', width: header.width, textAlign: 'center' }, (['standard', 'unit'].includes(header.key) && {
                                    display: { xs: 'none', sm: 'table-cell' },
                                })), children: header.label }, header.key))) }) }), _jsx(HealthCheckTableBody, { healthCheckStandardFlatten: healthCheckStandardFlatten, isAbnormal: isAbnormal, isKeyOfHealthcheckStandard: isKeyOfHealthcheckStandard, records: healthCheck.records })] }) }) }));
};
const HealthCheckTableBody = ({ healthCheckStandardFlatten, isAbnormal, isKeyOfHealthcheckStandard, records, }) => {
    return (_jsx(TableBody, { children: healthCheckParameters.map(({ key, label, options, unit }, paramIndex) => {
            const healthCheckParam = isKeyOfHealthcheckStandard(key)
                ? healthCheckStandardFlatten === null || healthCheckStandardFlatten === void 0 ? void 0 : healthCheckStandardFlatten[key]
                : undefined;
            return (_jsxs(TableRow, { children: [_jsx(HealthCheckRowHeaders, { checklistLabel: label, max: isNumberHealthcheckParam(healthCheckParam)
                            ? healthCheckParam.high
                            : undefined, min: isNumberHealthcheckParam(healthCheckParam)
                            ? healthCheckParam.low
                            : undefined, unit: unit }), records.map((record, index) => {
                        return key === 'uploaded_at' ? (_jsx(UploadedAtCell, { uploadedAt: record.uploaded_at, uploader_category: record.uploader_category }, `${record.id}-${key}`)) : (_jsx(HCTd, { healthCheckParam: healthCheckParam, isAbnormal: isAbnormal, options: options, name: key, value: record[key] }, `records.${index}.${key}`));
                    })] }, `table-row-${key}-${paramIndex}`));
        }) }));
};
// ダミーデータ生成関数
const generateDummyHealthcheckRecord = (index) => {
    const randomNumber = () => Math.floor(Math.random() * 1000) + 1;
    const consultation_date = appDayjs()
        .subtract((index + 1) * 5, 'day')
        .format('YYYY-MM-DD');
    const uploaded_at = appDayjs()
        .subtract(index * 2, 'day')
        .format('YYYY-MM-DD');
    return {
        id: `${randomNumber()}`,
        consultation_date,
        height: 175,
        weight: 70,
        bmi: 22,
        abdominal_circumference: 80,
        eyesight_right: 1.5,
        eyesight_left: 1.5,
        hearing_1000_right: true,
        hearing_1000_left: true,
        hearing_4000_right: false,
        hearing_4000_left: false,
        blood_pressure_systolic: 120,
        blood_pressure_diastolic: 80,
        total_cholesterol: 180,
        ldl_cholesterol: 100,
        hdl_cholesterol: 50,
        triglycerides: 70,
        ast: 10,
        alt: 5,
        g_gtp: 20,
        blood_sugar_level: 90,
        hba1c: 5.5,
        hb: 14,
        rbc: 500,
        hematocrit: 45,
        mcv: 90,
        mch: 30,
        mchc: 33,
        plt: 250,
        wbc: 7000,
        neutro: 50,
        lympho: 40,
        mono: 7,
        eosino: 2,
        baso: 1,
        urine_sugar: 'negative',
        urine_protein: 'negative',
        urine_occult_blood: 'negative',
        urine_urobilinogen: 'normal',
        urine_bilirubin: 'normal',
        urine_ketone: 'negative',
        urine_ph: 6,
        urine_specific_gravity: 1.02,
        bun: 15,
        e_gfr: 90,
        has_dm: false,
        is_smoking: false,
        uses_antihypertensive: false,
        ecg: 'normal',
        history: 'no significant history',
        medication: 'none',
        file_url: 'https://example.com/file',
        uploaded_at,
        uploader_category: index % 2 === 0 ? 'company' : index === 1 ? 'clinic' : 'patient',
    };
};
// ダミーデータの配列を生成
const dummyHealthcheckRecords = Array.from({ length: 8 }, (_, index) => generateDummyHealthcheckRecord(index));
// 日付の降順でソート
export const sortedHealthcheckRecords = dummyHealthcheckRecords.sort((a, b) => {
    // 日付を dayjs でパースして比較
    const dateA = appDayjs(a.consultation_date);
    const dateB = appDayjs(b.consultation_date);
    // 日付の降順でソート
    return dateB.diff(dateA);
});
